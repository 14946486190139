import React from 'react';
import { Typography, styled } from '@mui/material';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import TableContent from 'components/table/TableContent';
import TableBody from 'components/table/TableBody';
import { groupedExtractWhithDateTableTemplate } from 'pages/financial/bank-statement/extractTableTemplate';
import TableContainer from 'components/table/TableContainer';
import { moneyFormat } from 'helpers/numberFormat';
import GroupedBankStatementItemTable from '../grouped/GroupedBankStatementItemTable';
import { GroupedDataType } from 'pages/financial/bank-statement/hooks/useExtracts';

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
  '&.headerItemNumeric': {
    justifyContent: 'flex-end',
  },
});

const Description = styled(Typography)({
  fontSize: '11pt',
  fontWeight: 600,
  marginTop: 10,
  marginLeft: 10,
  color: '#30519f',
});

const Total = styled(Typography)({
  textAlign: 'end',
  fontSize: '9pt',
  gridColumn: '3/4',
  padding: '5px 10px',
  fontWeight: 600,
  '&.description': {
    gridColumn: '2/3',
  },
});

interface GroupedTypeBankStatementListTableProps {
  groupedDataByType: GroupedDataType[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
}

const GroupedTypeBankStatementListTable: React.FC<GroupedTypeBankStatementListTableProps> = ({
  handleSort,
  orderedIndex,
  groupedDataByType: groupedData,
}) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <>
      <TableContainer tableTemplate={groupedExtractWhithDateTableTemplate}>
        <TableHeader>
          {groupedExtractWhithDateTableTemplate.map((item, index) => (
            <HeaderItem
              className={item.dataType === 'number' ? 'headerItemNumeric' : ''}
              key={index}
              onClick={() => handleSort(item.originalId)}
            >
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>

              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </HeaderItem>
          ))}
        </TableHeader>

        {groupedData
          .sort((a, b) => {
            if (a.type < b.type) {
              return -1;
            }
            if (a.type > b.type) {
              return 1;
            }
            return 0;
          })
          .map((data, index) => (
            <TableContent key={index} style={{ marginTop: 10 }} useMinHeight={false}>
              <Description>{data.formattedType}</Description>

              <TableBody useMaxHeight={true}>
                {data.result.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((account, _index) => (
                  <TableRow style={{ minHeight: 20 }} activeNthChild key={_index}>
                    <GroupedBankStatementItemTable account={account} />
                  </TableRow>
                ))}
                <TableRow style={{ minHeight: 20 }} activeNthChild>
                  <Total className="description">Total:</Total>
                  <Total>{moneyFormat(data.total)}</Total>
                </TableRow>
              </TableBody>
            </TableContent>
          ))}
      </TableContainer>
    </>
  );
};

export default GroupedTypeBankStatementListTable;
