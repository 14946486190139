import { Dispatch, SetStateAction, useState } from 'react';
import * as yup from 'yup';

export type ResellerApprovalValidation = {
  segmentId?: string;
  seller?: string;
};

type UseResellerApprovalValidation = [
  ResellerApprovalValidation,
  Dispatch<SetStateAction<ResellerApprovalValidation>>,
  (payload: any, type?: 'update' | 'store') => Promise<void>,
];

export function useResellerApprovalValidation(): UseResellerApprovalValidation {
  async function handleValidation(payload: any, type = 'store') {
    const schema = yup.object().shape({
      segmentId: yup.number().typeError('A atividade é obrigatória').required('A atividade é obrigatória'),
      seller:
        type === 'store'
          ? yup.object().typeError('O vendedor é obrigatório').required('O vendedor é obrigatório')
          : yup.object().nullable(),
    });

    try {
      await schema.validate(payload);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<ResellerApprovalValidation>({});
  return [validation, setValidation, handleValidation];
}
