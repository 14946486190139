import React from 'react';
import { AgroupedAbcProductPayment } from 'types/abcProduct';
import { useTable } from 'components/table/hook/useTable';
import { Typography } from '@mui/material';

type AgroupedPaymentItemTableProps = {
  payment: AgroupedAbcProductPayment;
};

const AgroupedPaymentItemTable: React.FC<AgroupedPaymentItemTableProps> = ({ payment }) => {
  const { tableTemplate } = useTable();

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item => (
          <div key={item.id} className={item.dataType === 'number' ? 'numericData' : ''}>
            <Typography style={{ fontSize: 11 }} variant="body2">
              {payment[item.id as keyof AgroupedAbcProductPayment]}
            </Typography>
          </div>
        ))}
    </>
  );
};

export default AgroupedPaymentItemTable;
