import React, { useState, useEffect } from 'react';
import Appbar from 'components/appbar/Appbar';
import FinancialTab from './FinancialTab';
import FinancialSummary from 'pages/reports/financial-summary/FinancialSummary';
import Bills from 'pages/bills/Bills';
import Billing from 'pages/billing/Billing';
import Revenues from './revenues/Revenues';
import MovimentQuery from 'pages/moviment/query/Invoices';
import Expenses from './expenses/Expenses';
import AppropriationDatePage from 'pages/appropiation-date/AppropriationDate';
import ConstructionPage from 'components/construction/ConstructionPage';
import FinancialTicketReport from 'pages/reports/financial/receivable/ticket/FinancialTicketReport';
import FinancialCardReport from 'pages/reports/financial/receivable/card/FinancialCardReport';
import { styled } from '@mui/material';
import InvoicesResume from 'pages/moviment/resume/FinancialResume';
import { useApp } from 'hooks/app';
import FinancialHome from './home/FinancialHome';
import { FinancialProvider } from './hooks/useFinancial';
import TotalizingAccountPage from './totalizing-account/TotalizingAccount';
import TransferAccountPage from './transfer-account/TransferAccountPage';
import BankStatement from './bank-statement/BankStatement';
import { useParams } from 'react-router-dom';
import history from 'services/history';
import FinancialTicketPage from './ticket/FinancialTicket';
import { usePermissionRules } from 'hooks/permissionRules';

const Space = styled('div')(({ theme }) => ({
  marginTop: 48,
  [theme.breakpoints.down('sm')]: {
    marginTop: 0,
  },
}));

const FinancialPage: React.FC = () => {
  const { windowWidth } = useApp();
  const { checkPermission } = usePermissionRules();
  const { param } = useParams<{ param: string }>();
  const [tab, setTab] = useState(windowWidth < 600 ? 'home' : param || 'resume');

  useEffect(() => {
    if (!param) {
      setTab('construction-page');
      return;
    }

    setTab(param);

    history.push(`/financial/${param}`);
  }, [param, windowWidth]);

  useEffect(() => {
    if (windowWidth >= 600 && tab === 'home') {
      setTab('resume');
      history.push(`/financial/resume`);
    }
  }, [windowWidth, tab]);

  function handleBack() {
    setTab('home');
    history.push('/financial/home');
  }

  return (
    <FinancialProvider value={{ handleBack }}>
      <Appbar title="Financeiro" />
      {windowWidth >= 600 && <FinancialTab handleChange={tab => history.push(`/financial/${tab}`)} tab={tab} />}
      <Space />
      {tab === 'home' ? (
        <FinancialHome handleChange={tab => history.push(`/financial/${tab}`)} />
      ) : tab === 'resume' ? (
        checkPermission('financial.resume') && <FinancialSummary />
      ) : tab === 'charge_bills' ? (
        checkPermission('financial.charge.bills') && <Bills />
      ) : tab === 'charge_settings' ? (
        checkPermission('financial.charge.bill_settings') && <Billing />
      ) : tab === 'register_revenues' ? (
        checkPermission('financial.revenues') && <Revenues />
      ) : tab === 'register_expenses' ? (
        checkPermission('financial.expenses') && <Expenses />
      ) : tab === 'register_appropriation-date' ? (
        checkPermission('financial.appropriation-date') && <AppropriationDatePage />
      ) : tab === 'report_query' ? (
        checkPermission('financial.report.query') && <MovimentQuery type="TT" />
      ) : tab === 'report_classification' ? (
        checkPermission('financial.report.classification') && <InvoicesResume />
      ) : tab === 'report_card' ? (
        checkPermission('financial.report.card') && <FinancialCardReport />
      ) : tab === 'report_ticket' ? (
        checkPermission('financial.report.ticket') && <FinancialTicketPage />
      ) : tab === 'billing-management' ? (
        checkPermission('financial.charge.billing-management') && <FinancialTicketReport />
      ) : tab === 'totalizing_account' ? (
        checkPermission('financial.totalizing_account') && <TotalizingAccountPage />
      ) : tab === 'moviment_transfer-account' ? (
        checkPermission('financial.moviment.transfer-account') && <TransferAccountPage />
      ) : tab === 'moviment_extract' ? (
        checkPermission('financial.moviment.extract') && <BankStatement />
      ) : tab === 'moviment_fp' ? (
        checkPermission('financial.moviment.fp') && <MovimentQuery type="F" />
      ) : tab === 'moviment_expenses' ? (
        checkPermission('financial.moviment.expenses') && <MovimentQuery type="D" />
      ) : tab === 'moviment_revenues' ? (
        checkPermission('financial.moviment.revenues') && <MovimentQuery type="R" />
      ) : tab === 'moviment_titles' ? (
        checkPermission('financial.moviment.titles') && <MovimentQuery type="T" />
      ) : (
        <ConstructionPage />
      )}
    </FinancialProvider>
  );
};

export default FinancialPage;
