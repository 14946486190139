import { ArrowDownward, ArrowUpward, MoreHoriz } from '@mui/icons-material';
import { Checkbox, FormControlLabel, IconButton, Typography, styled } from '@mui/material';
import Dialog from 'components/dialogs/Dialog';
import { moneyFormat } from 'helpers/numberFormat';
import useTableOrder from 'hooks/tableOrder';
import React, { useState } from 'react';
import { FinancialTicket } from 'types/financialReportList';
import FinancialTicketMenu from '../FinancialTicketMenu';
import { useFinancialTicket } from '../hooks/useFinancialTicket';
import PaginationProvider from 'hooks/pagination';
import Pagination from 'components/pagination/Pagination';
import { api } from 'services/api';
import TicketActions from '../actions/TicketActions';
import FailedSendTicketsDialog from '../error/FailedSendTicketsDialog';
import Loading from 'components/loading/Loading';
import { useApp } from 'hooks/app';
import { useMessaging } from 'hooks/messaging';

interface FinancialCustomerTicketsDialogProps {
  onExited(): void;
  tickets: FinancialTicket[];
}

const Container = styled('div')({
  gap: 5,
  display: 'flex',
  flexDirection: 'column',
});

const Infos = styled('div')(({ theme }) => ({
  gap: 5,
  display: 'grid',
  gridTemplateColumns: '130px 1fr',
  '& > span': {
    fontSize: 12,
  },
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const List = styled('div')({
  gap: 5,
  overflow: 'auto',
  display: 'flex',
  padding: '10px 0',
  flexDirection: 'column',
});

const Grid = styled('div')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '40px 60px 1fr 110px 110px 100px 70px 80px 80px 100px',
  '& > div': {
    gap: 5,
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',
    '& > svg': {
      fontSize: 11,
    },
    '&.numericData': {
      justifyContent: 'end',
    },
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '120px 60px 110px 110px 100px 70px 80px 80px 100px',
  },
}));

const HeaderItem = styled('span')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Flex = styled('div')({
  display: 'flex',
  flex: 1,
  width: '100%',
  '& > .actions': {
    zoom: '90%',
  },
});

const FinancialCustomerTicketsDialog: React.FC<FinancialCustomerTicketsDialogProps> = ({ onExited, tickets }) => {
  const { h2iApi } = useApp();
  const { handleOpen } = useMessaging();
  const total = tickets.reduce((sum, item) => sum + item.valor_total, 0);
  const [filtered, setFiltered] = useState<FinancialTicket[]>(tickets);
  const [orderedIndex, sort] = useTableOrder();
  const [saving, setSaving] = useState(false);
  const { setSelectedTicket, selectedTicket } = useFinancialTicket();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [selectedTickets, setSelectedTickets] = useState<FinancialTicket[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [failedTickets, setFailedTickets] = useState<FinancialTicket[]>([]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleToggle(ticket: FinancialTicket) {
    if (selectedTickets.some(_selectedTicket => _selectedTicket.id === ticket.id)) {
      setSelectAll(false);
      setSelectedTickets(selectedTickets.filter(_selectedTicket => _selectedTicket.id !== ticket.id));
    } else {
      const _tickets = [...selectedTickets, ticket];
      setSelectedTickets(_tickets);

      if (filtered.length === _tickets.length) {
        setSelectAll(true);
      }
    }
  }

  function handleSelectAll() {
    if (selectAll) {
      setSelectedTickets([]);
    } else {
      setSelectedTickets(filtered);
    }
    setSelectAll(!selectAll);
  }

  const isChecked = (ticket: FinancialTicket) => {
    return selectedTickets.some(selectedExtract => selectedExtract.id === ticket.id);
  };

  function onClick(e: React.MouseEvent<HTMLButtonElement>, ticket: FinancialTicket) {
    setAnchorEl(e.currentTarget);

    setSelectedTicket(ticket);
  }

  async function sendReminder() {
    if (selectedTickets.length === 0) {
      return;
    }

    setSaving(true);

    const results = await Promise.allSettled(
      selectedTickets.map(async ticket => {
        if (!ticket.url) {
          throw new Error(`Boleto #${ticket.id} não disponível`);
        }
        const response = await api.post('/email-templates/14/__send', {
          ticket_id: ticket.id,
          due_date: ticket.vencimento,
          ticket_link: ticket.url,
          emails: [ticket.email],
          base_template_id: 15,
          street: '.',
          neighborhood: '.',
          city: '.',
          state: 'Bahia',
          postal_code: '.',
          phone: '.',
          company_name: 'Eltecno Distribuição de Informática LTDA',
        });

        if (!response.data.ok) {
          throw new Error(`Erro ao enviar boleto #${ticket.id}`);
        }
      }),
    ).finally(() => setSaving(false));

    const failed = selectedTickets.filter((_, index) => results[index].status === 'rejected').filter(item => !item.url);
    const success = selectedTickets
      .filter((_, index) => results[index].status !== 'rejected')
      .filter(item => item.url)
      .map(item => ({
        id: item.id,
        id_filial: item.id_filial,
      }));

    if (h2iApi) {
      h2iApi.post('/api/savelembreteemailfinanceiro', { email: success });
    }
    setFailedTickets(failed);

    handleOpen(
      `Foram enviados ${selectedTickets.length - failed.length}/${selectedTickets.length} lembretes de vencimento`,
    );
  }

  async function sendAlert() {
    if (selectedTickets.length === 0) {
      return;
    }

    setSaving(true);

    const results = await Promise.allSettled(
      selectedTickets.map(async ticket => {
        if (!ticket.url) {
          throw new Error(`Boleto #${ticket.id} não disponível`);
        }
        const response = await api.post('/email-templates/16/__send', {
          ticket_id: ticket.id,
          due_date: ticket.vencimento,
          ticket_link: ticket.url,
          emails: [ticket.email],
          base_template_id: 15,
          street: '.',
          neighborhood: '.',
          city: '.',
          state: 'Bahia',
          postal_code: '.',
          phone: '.',
          company_name: 'Eltecno Distribuição de Informática LTDA',
        });

        if (!response.data.ok) {
          throw new Error(`Erro ao enviar boleto #${ticket.id}`);
        }
      }),
    ).finally(() => setSaving(false));

    const success = selectedTickets
      .filter((_, index) => results[index].status !== 'rejected')
      .filter(item => item.url)
      .map(item => ({
        id: item.id,
        id_filial: item.id_filial,
      }));

    const failed = selectedTickets.filter((_, index) => results[index].status === 'rejected').filter(item => !item.url);
    if (h2iApi) {
      h2iApi.post('/api/saveavisoemailfinanceiro', { email: success });
    }

    setFailedTickets(failed);

    handleOpen(`Foram enviados ${selectedTickets.length - failed.length}/${selectedTickets.length} avisos de débito`);
  }

  return (
    <Dialog title={tickets[0].cliente} onExited={onExited}>
      {saving && <Loading />}
      <FinancialTicketMenu selectedTicket={selectedTicket} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      {failedTickets.length > 0 && (
        <FailedSendTicketsDialog failedTickets={failedTickets} onExited={() => setFailedTickets([])} />
      )}

      <PaginationProvider>
        <Container>
          <Infos>
            <Typography variant="subtitle2">Qtd de boletos:</Typography>
            <Typography variant="subtitle2" fontWeight={600}>
              {tickets.length}
            </Typography>

            <Typography variant="subtitle2">Cliente:</Typography>
            <Typography variant="subtitle2" fontWeight={600}>
              {tickets[0].cliente}
            </Typography>

            <Typography variant="subtitle2">E-mail:</Typography>
            <Typography variant="subtitle2" fontWeight={600}>
              {tickets[0].email}
            </Typography>

            <Typography variant="subtitle2">CNPJ:</Typography>
            <Typography variant="subtitle2" fontWeight={600}>
              {tickets[0].cnpj_cpf}
            </Typography>

            <Typography variant="subtitle2">Total de boletos:</Typography>
            <Typography variant="subtitle2" fontWeight={600}>
              {moneyFormat(total)}
            </Typography>
          </Infos>

          <Typography variant="h6">Lista:</Typography>

          <List>
            <Grid className="header">
              <HeaderItem>
                <FormControlLabel
                  style={{ justifyContent: 'center', marginRight: 0 }}
                  label=""
                  control={
                    <Checkbox checked={selectAll} onChange={handleSelectAll} size="small" style={{ padding: 0 }} />
                  }
                />
              </HeaderItem>
              <div>
                <Typography variant="h6" fontSize={14}>
                  Ações
                </Typography>
              </div>
              <div>
                <Typography onClick={() => handleSort('filial')} variant="h6" fontSize={14}>
                  Filial
                </Typography>
                {orderedIndex.index === 'filial' && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward fontSize="small" color="primary" />
                    ) : (
                      <ArrowDownward fontSize="small" color="primary" />
                    )}
                  </>
                )}
              </div>
              <div>
                <Typography onClick={() => handleSort('formattedReleaseDate')} variant="h6" fontSize={14}>
                  Lançamento
                </Typography>
                {orderedIndex.index === 'formattedReleaseDate' && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward fontSize="small" color="primary" />
                    ) : (
                      <ArrowDownward fontSize="small" color="primary" />
                    )}
                  </>
                )}
              </div>
              <div>
                <Typography onClick={() => handleSort('formattedPayDate')} variant="h6" fontSize={14}>
                  Pagamento
                </Typography>
                {orderedIndex.index === 'formattedPayDate' && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward fontSize="small" color="primary" />
                    ) : (
                      <ArrowDownward fontSize="small" color="primary" />
                    )}
                  </>
                )}
              </div>
              <div>
                <Typography onClick={() => handleSort('formattedDueDate')} variant="h6" fontSize={14}>
                  Vencimento
                </Typography>
                {orderedIndex.index === 'formattedDueDate' && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward fontSize="small" color="primary" />
                    ) : (
                      <ArrowDownward fontSize="small" color="primary" />
                    )}
                  </>
                )}
              </div>
              <div className="numericData">
                <Typography onClick={() => handleSort('valor')} variant="h6" fontSize={14} align="right">
                  Valor
                </Typography>
                {orderedIndex.index === 'valor' && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward fontSize="small" color="primary" />
                    ) : (
                      <ArrowDownward fontSize="small" color="primary" />
                    )}
                  </>
                )}
              </div>
              <div className="numericData">
                <Typography onClick={() => handleSort('juros')} variant="h6" fontSize={14} align="right">
                  Juros
                </Typography>
                {orderedIndex.index === 'juros' && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward fontSize="small" color="primary" />
                    ) : (
                      <ArrowDownward fontSize="small" color="primary" />
                    )}
                  </>
                )}
              </div>
              <div className="numericData">
                <Typography onClick={() => handleSort('multa')} variant="h6" fontSize={14} align="right">
                  Multa
                </Typography>
                {orderedIndex.index === 'multa' && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward fontSize="small" color="primary" />
                    ) : (
                      <ArrowDownward fontSize="small" color="primary" />
                    )}
                  </>
                )}
              </div>
              <div className="numericData">
                <Typography onClick={() => handleSort('valor_total')} variant="h6" fontSize={14} align="right">
                  Total
                </Typography>
                {orderedIndex.index === 'valor_total' && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward fontSize="small" color="primary" />
                    ) : (
                      <ArrowDownward fontSize="small" color="primary" />
                    )}
                  </>
                )}
              </div>
            </Grid>
            {filtered.map(ticket => (
              <Grid key={ticket.id}>
                <FormControlLabel
                  style={{ justifyContent: 'center', marginRight: 0 }}
                  label=""
                  control={
                    <Checkbox
                      size="small"
                      style={{ padding: 0 }}
                      checked={isChecked(ticket)}
                      onChange={() => handleToggle(ticket)}
                    />
                  }
                />
                <div>
                  <IconButton onClick={e => onClick(e, ticket)} size="small">
                    <MoreHoriz />
                  </IconButton>
                </div>
                <Typography variant="caption">{ticket.filial}</Typography>
                <Typography variant="caption">{ticket.data_lanc}</Typography>
                <Typography variant="caption">{ticket.data_pag}</Typography>
                <Typography variant="caption">{ticket.vencimento}</Typography>
                <Typography variant="caption" align="right">
                  {ticket.formattedValue}
                </Typography>
                <Typography variant="caption" align="right">
                  {ticket.formattedFees}
                </Typography>
                <Typography variant="caption" align="right">
                  {ticket.formattedTrafficTicket}
                </Typography>
                <Typography variant="caption" align="right">
                  {ticket.formattedTotal}
                </Typography>
              </Grid>
            ))}
          </List>

          <Flex>
            <TicketActions
              showAlert
              showReminder
              sendAlert={sendAlert}
              sendReminder={sendReminder}
              tickets={selectedTickets}
            />

            <Pagination count={filtered.length} />
          </Flex>
        </Container>
      </PaginationProvider>
    </Dialog>
  );
};

export default FinancialCustomerTicketsDialog;
