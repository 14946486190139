import React, { FormEvent } from 'react';
import FilterBox from 'components/filter-box/FilterBox';
import { FinancialTicketFilterParams } from './FinancialTicketReport';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { Button, MenuItem, TextField, styled } from '@mui/material';
import SelectBranch from 'components/select-branches/SelectBranch';
import { Search } from '@mui/icons-material';
import { BillSetting } from 'types/billSetting';

const Filter = styled('form')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '0.6fr 0.6fr 0.6fr 0.6fr 0.6fr 1fr 150px 1fr 100px',
  columnGap: 10,
  rowGap: 15,
  flex: 1,
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
  '& > .search-params': {
    display: 'grid',
    columnGap: 10,
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  '& .MuiInputBase-root': {
    '& .MuiInputAdornment-root': {
      marginRight: 3,
      marginLeft: 3,
    },
  },
}));

const Select = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

interface FinancialTicketFilterBoxProps {
  settings: BillSetting[];
  loading: boolean;
  filter: FinancialTicketFilterParams;
  handleChangeFilter(index: keyof FinancialTicketFilterParams, value: any): void;
  handleSearchSubmit(event: FormEvent<HTMLFormElement>): void;
}

const FinancialTicketFilterBox: React.FC<FinancialTicketFilterBoxProps> = ({
  filter,
  handleChangeFilter,
  handleSearchSubmit,
  settings,
  loading,
}) => {
  return (
    <FilterBox>
      <Filter onSubmit={handleSearchSubmit}>
        <SelectBranch branch_id={filter.branchId} handleChange={e => handleChangeFilter('branchId', e)} />

        <TextField
          select
          fullWidth
          placeholder="Selecionar layout..."
          value={filter.bank_billet_setting_id}
          onChange={e => handleChangeFilter('bank_billet_setting_id', parseInt(e.target.value))}
          label="Layout"
        >
          {settings.map(bill => (
            <MenuItem key={bill.id} value={bill.id}>
              {bill.bank_name}
            </MenuItem>
          ))}
        </TextField>

        <Select
          value={filter.typeDate}
          onChange={e => handleChangeFilter('typeDate', e.target.value)}
          fullWidth
          select
          label="Tipo da data"
        >
          <MenuItem value={'V'}>Vencimento</MenuItem>
          <MenuItem value={'L'}>Lançamento</MenuItem>
          <MenuItem value={'P'}>Pagamento</MenuItem>
        </Select>

        <Select
          value={filter.situation}
          onChange={e => handleChangeFilter('situation', e.target.value)}
          fullWidth
          select
          label="Situação"
        >
          <MenuItem value={'I'}>Inadimplentes</MenuItem>
          <MenuItem value={'A'}>Apropriados</MenuItem>
          <MenuItem value={'N'}>Não apropriados</MenuItem>
          <MenuItem value={'T'}>Todos</MenuItem>
        </Select>

        <Select
          value={filter.dropoffLocation}
          onChange={e => handleChangeFilter('dropoffLocation', e.target.value)}
          fullWidth
          select
          label="Local Baixa"
        >
          <MenuItem value={'L'}>Manual</MenuItem>
          <MenuItem value={'B'}>Boleto</MenuItem>
          <MenuItem value={'P'}>Pix</MenuItem>
          <MenuItem value={'T'}>Todos</MenuItem>
        </Select>

        <div className="search-params">
          <KeyboardDatePicker
            label="Data inicial"
            value={filter.initialDate}
            onChange={date => handleChangeFilter('initialDate', date)}
          />
          <KeyboardDatePicker
            label="Data Final"
            value={filter.finalDate}
            onChange={date => handleChangeFilter('finalDate', date)}
          />
        </div>

        <TextField
          label="Dias inadimplência"
          type="number"
          placeholder="Dias inadimplência"
          value={filter.days}
          autoFocus
          onChange={e => handleChangeFilter('days', parseInt(e.target.value))}
        />

        <TextField
          label="Pesquisar"
          placeholder="Digite sua pesquisa"
          autoFocus
          value={filter.search}
          onChange={e => handleChangeFilter('search', e.target.value)}
        />
        <Button
          disabled={loading}
          type="submit"
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Search />}
        >
          Buscar
        </Button>
      </Filter>
    </FilterBox>
  );
};

export default FinancialTicketFilterBox;
