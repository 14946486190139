import React, { Dispatch, SetStateAction, useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { CustomerFinancialTicket } from 'types/financialReportList';
import ConfirmDialog from 'components/confirm-dialog/ConfirmDialog';
import { useMessaging } from 'hooks/messaging';
import { useApp } from 'hooks/app';
import { usePermissionRules } from 'hooks/permissionRules';
import { useFinancialTicket } from '../hooks/useFinancialTicket';

type CustomersTicketMenuProps = {
  selectedTicket: CustomerFinancialTicket;
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const CustomersTicketMenu: React.FC<CustomersTicketMenuProps> = ({ selectedTicket, anchorEl, setAnchorEl }) => {
  const [confirmDialog, setConfirmDialog] = useState(false);
  const { h2iApi } = useApp();
  const { handleOpen } = useMessaging();
  const { checkPermission } = usePermissionRules();
  const [saving, setSaving] = useState(false);
  const { handleSearch } = useFinancialTicket();

  function onSave() {
    if (!h2iApi) return;
    if (!checkPermission('financial.reports.ticket.button-reset-credit-limit')) {
      handleOpen('Sem permissão');
      return;
    }

    setSaving(true);

    h2iApi
      .post('/api/zerarlimitecredito', [selectedTicket.cnpj_cpf])
      .then(() => {
        handleOpen('Limite de crédito zerado com sucesso');
        setConfirmDialog(false);
        handleSearch();
        setAnchorEl(null);
      })
      .catch(() => {
        handleOpen('Erro ao zerar limite de crédito');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function sendMessage() {
    const text = `Olá, *${selectedTicket?.nome}*!%0A%0ANotamos que você tem um ou mais boletos em aberto com vencimento nos próximos dias.%0A%0ASe já efetuou o pagamento, favor desconsiderar esta mensagem.%0A%0AQualquer dúvida, estamos à disposição!%0A%0A*Grupo H2i*`;
    window.open(`https://api.whatsapp.com/send?phone=55${selectedTicket?.telefone}&text=${text}`);
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      {confirmDialog && selectedTicket && (
        <ConfirmDialog
          handleNoClick={() => setConfirmDialog(false)}
          handleYesClick={onSave}
          title="Deseja zerar o limite de crédito?"
        />
      )}
      <MenuItem onClick={sendMessage}>Enviar lembrete de vencimento (WhatsApp)</MenuItem>

      <MenuItem
        disabled={!checkPermission('financial.charge.billing-management.button-reset-credit-limit') || saving}
        onClick={() => setConfirmDialog(true)}
      >
        Zerar limite de crédito
      </MenuItem>
    </Menu>
  );
};

export default CustomersTicketMenu;
