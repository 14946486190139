import React, { useEffect, useState } from 'react';
import Appbar from 'components/appbar/Appbar';
import { endOfDay, startOfMonth } from 'date-fns';
import InvoicesFilterBox from './filter/InvoicesFilterBox';
import TableContainer from 'components/table/TableContainer';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import { Invoice } from 'types/invoices';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import Pagination from 'components/pagination/Pagination';
import useTableOrder from 'hooks/tableOrder';
import { invoicesTableTemplate } from './invoicesTableTemplate';
import { styled } from '@mui/material';
import InvoiceTableList from './list/table/InvoiceTableList';
import InvoiceModuleList from './list/module/InvoiceModuleList';
import InvoicesMoreFilter from './filter/InvoicesMoreFilter';
import { InvoicesProvider } from './hooks/useInvoices';
import InvoicesTotal from './InvoicesTotal';
import { useInvoicesTotal } from './hooks/useInvoicesTotal';
import { useFetchInvoices } from './hooks/useFetchInvoices';
import InvoicesReport from './report/InvoicesReport';
import PageHeaderButtons from './PageHeaderButtons';
import NewInvoice from './registration/NewInvoice';
import EditInvoice from './registration/edit/EditInvoice';
import InvoicesAppbarActions from './InvoicesAppbarActions';
import { useDisplayMode } from 'hooks/useDisplayMode';
import { useFinancial } from 'pages/financial/hooks/useFinancial';
import { useMessaging } from 'hooks/messaging';
import InvoiceAppropriation from './dialog/InvoiceAppropriation';
import InvoicePayment from './dialog/InvoicePayment';
import InvoicesTotals from './InvoicesTotals';
import { useApp } from 'hooks/app';

export interface InvoicesFilterProps {
  ignorePeriod: string;
  initial_date: Date;
  final_date: Date;
  branch_id: number;
  supplier: string;
  classification: string;
  informedAccount: string;
  totalizingAccountId: number;
  invoice_number: string;
  type: 'T' | 'DT' | 'FP' | 'D' | 'TT';
  month_year: Date | null;
  appropriation_date: Date | null;
  is_appropriated: 'S' | 'N' | 'T';
  is_paid: 'S' | 'N' | 'T';
}

const filterInitialState: InvoicesFilterProps = {
  ignorePeriod: 'N',
  appropriation_date: null,
  initial_date: startOfMonth(new Date()),
  final_date: endOfDay(new Date()),
  branch_id: 1001,
  type: 'TT' as any,
  month_year: null,
  invoice_number: '',
  supplier: '',
  classification: '',
  is_appropriated: 'T' as any,
  informedAccount: 'T',
  totalizingAccountId: 0,
  is_paid: 'T',
};

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  zoom: '90%',
  [theme.breakpoints.down('md')]: {
    zoom: '80%',
  },
  [theme.breakpoints.down('sm')]: {
    zoom: '100%',
  },
}));

export const invoiceTypes = {
  TT: 'Todos',
  T: 'Título',
  D: 'Despesa',
  R: 'Receita',
  F: 'Folha',
  S: 'Transferência',
};

export const invoiceColors = {
  TT: 'Todos',
  T: '#c3881d',
  D: '#ff0000',
  R: 'green',
  F: '#30519f',
  S: 'Transferência',
};

interface InvoicesProps {
  type: string;
}

const Invoices: React.FC<InvoicesProps> = ({ type }) => {
  const { handleBack } = useFinancial();
  const { handleOpen } = useMessaging();
  const { isOpenedMenu, handleOpenMenu } = useApp();
  const [selectedInvoices, setSelectedInvoices] = useState<Invoice[]>([]);
  const [displayMode] = useDisplayMode();
  const [filter, setFilter] = useState<InvoicesFilterProps>(filterInitialState);
  const [filtered, setFiltered] = useState<Invoice[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const [isOpenFilterMore, setIsOpenFilterMore] = useState(false);
  const [invoices, setInvoices, loading, accounts, fetch] = useFetchInvoices(filter, type);
  const invoicesTotal = useInvoicesTotal(filtered, loading);
  const [isReportOpen, setIsReportOpen] = useState(false);
  const [isNewInvoiceOpen, setIsNewInvoiceOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
  const [showTotals, setShowTotals] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [appropriationDialog, setAppropriationDialog] = useState(false);
  const [paymentDialog, setPaymentDialog] = useState(false);
  const [selectedBranchesId, setSelectedBranchesId] = useState<number[]>([]);

  useEffect(() => {
    setInvoices([]);
    setSelectedInvoices([]);
    setSelectAll(false);
    setSelectedInvoice(null);
    setSelectedBranchesId([]);
  }, [type, filter.branch_id, setInvoices]);

  const handleSelectAll = () => {
    if (filter.branch_id === 1001) {
      handleOpen('Informe uma filial para poder selecionar todos');
      return;
    }
    if (selectAll) {
      setSelectedInvoices([]);
    } else {
      setSelectedInvoices(invoices.filter(item => item.apropriado === 'N'));
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    const _invoices =
      selectedBranchesId.length > 0
        ? invoices.filter(invoice => selectedBranchesId.includes(parseInt(invoice.id_filial.toString())))
        : invoices;
    setFiltered(_invoices);
  }, [invoices, selectedBranchesId]);

  function handleChangeSelectedBranches(ids: number[]) {
    setSelectedBranchesId(ids);
  }

  function handleChangeFilter(index: keyof InvoicesFilterProps, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleSearch() {
    if (isOpenedMenu) handleOpenMenu();
    fetch();
  }

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleUpdateList() {
    fetch();
    onExited();
  }

  function onExited() {
    setPaymentDialog(false);
  }

  const handleToggle = (invoice: Invoice) => {
    if (filter.branch_id === 1001) {
      handleOpen('Informe uma filial para poder selecionar o título');
      return;
    }
    if (invoice.apropriado === 'S') {
      handleOpen('Não é possível selecionar uma fatura já apropriada');
      return;
    }
    if (selectedInvoices.some(_selectedInvoice => _selectedInvoice.id === invoice.id)) {
      setSelectedInvoices(selectedInvoices.filter(_selectedInvoice => _selectedInvoice.id !== invoice.id));
    } else {
      setSelectedInvoices([...selectedInvoices, invoice]);
    }
  };

  function onSaveAppropriation() {
    setAppropriationDialog(false);
    setSelectedInvoices([]);
    fetch();
  }

  return (
    <InvoicesProvider
      value={{
        handleChangeFilter,
        handleSearch,
        loading,
        filter,
        setIsOpenFilterMore,
        invoicesTotal,
        invoices,
        setIsReportOpen,
        setIsNewInvoiceOpen,
        selectedInvoice,
        setSelectedInvoice,
        handleUpdateList,
        showTotals,
        setShowTotals,
        type,
        accounts,
        setSelectedInvoices,
        selectedInvoices,
        handleToggle,
        selectAll,
        handleSelectAll,
        setAppropriationDialog,
        setPaymentDialog,
      }}
    >
      <Appbar backAction={handleBack} title={invoiceTypes[type]} ActionsComponent={<InvoicesAppbarActions />} />

      {paymentDialog && (
        <InvoicePayment
          branchId={filter.branch_id}
          onSave={handleUpdateList}
          invoices={selectedInvoices}
          onExited={onExited}
        />
      )}
      {appropriationDialog && (
        <InvoiceAppropriation onSave={onSaveAppropriation} onExited={() => setAppropriationDialog(false)} />
      )}

      {isOpenFilterMore && <InvoicesMoreFilter onExited={() => setIsOpenFilterMore(false)} />}

      {isReportOpen && <InvoicesReport onExited={() => setIsReportOpen(false)} />}

      {isNewInvoiceOpen && (
        <NewInvoice
          branchId={filter.branch_id}
          handleUpdateList={handleUpdateList}
          type={type}
          onExited={() => setIsNewInvoiceOpen(false)}
        />
      )}

      {selectedInvoice && (
        <EditInvoice
          id={selectedInvoice.id}
          handleUpdateList={handleUpdateList}
          type={type}
          onExited={() => setSelectedInvoice(null)}
        />
      )}

      <PageHeaderButtons title={invoiceTypes[type]} />

      <InvoicesFilterBox />

      <InvoicesTotals invoices={filtered} />

      {type === 'TT' && <InvoicesTotal />}

      <TableContainer tableTemplate={invoicesTableTemplate}>
        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum título para mostrar" />
        ) : (
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <InvoiceTableList
                  handleChangeSelectedBranches={handleChangeSelectedBranches}
                  selectedBranchesId={selectedBranchesId}
                  type={type}
                  invoices={invoices}
                  filtered={filtered}
                  handleSort={handleSort}
                  orderedIndex={orderedIndex}
                />
              ) : (
                displayMode === 'module' && <InvoiceModuleList invoices={filtered} />
              )}
              <Pagination count={filtered.length} />
            </Container>
          </PaginationProvider>
        )}
      </TableContainer>
    </InvoicesProvider>
  );
};

export default Invoices;
