import React, {
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useCallback,
  FormEvent,
} from 'react';
import {
  AbcProduct,
  AbcProductBranch,
  AbcProductCategory,
  AbcProductSeller,
  AbcProductSegment,
  AbcProductSubgroup,
  AbcProductUnity,
  AbcProductManufacturer,
  AbcProductCustomer,
  AbcProductPayment,
  AgroupedAbcProductPayment,
} from 'types/abcProduct';
import { format } from 'date-fns';
import { useApp } from 'hooks/app';
import { ptBR } from 'date-fns/locale';
import { moneyFormat, percentFormat } from 'helpers/numberFormat';
import { Sale } from '../modal/branch/SelectedBranchListMenu';
import useTableOrder, { OrderIndexData } from 'hooks/tableOrder';
import { AbcProductFilterParams } from '../AbcProduct';

export type AbcProductTotalProps = {
  total: number;
  coust: number;
  mc: number;
  spiff: number;
  unitary: number;
  quantity: number;
};

type AbcProductsContextValue = {
  selectedSale: Sale | null;
  setSelectedSale: Dispatch<SetStateAction<Sale | null>>;
  moreUnity: AbcProductUnity | null;
  setMoreUnity: Dispatch<SetStateAction<AbcProductUnity | null>>;
  selectedUnity: AbcProductUnity | null;
  setSelectedUnity(abcUnity: AbcProductUnity | null): void;
  moreSegment: AbcProductSegment | null;
  setMoreSegment: Dispatch<SetStateAction<AbcProductSegment | null>>;
  selectedSegment: AbcProductSegment | null;
  setSelectedSegment(abcSegment: AbcProductSegment | null): void;
  moreSubgroup: AbcProductSubgroup | null;
  setMoreSubgroup: Dispatch<SetStateAction<AbcProductSubgroup | null>>;
  selectedSubgroup: AbcProductSubgroup | null;
  setSelectedSubgroup(abcSubgroup: AbcProductSubgroup | null): void;
  moreBranch: AbcProductBranch | null;
  setMoreBranch: Dispatch<SetStateAction<AbcProductBranch | null>>;
  selectedBranch: AbcProductBranch | null;
  setSelectedBranch(abcBranch: AbcProductBranch | null): void;
  moreProduct: AbcProduct | null;
  setMoreProduct: Dispatch<SetStateAction<AbcProduct | null>>;
  selectedProduct: AbcProduct | null;
  setSelectedProduct(abcProduct: AbcProduct | null): void;
  filteredSegments: AbcProductSegment[];
  handleSortSegment(index: string): void;
  filteredProducts: AbcProduct[];
  handleSort(index: string): void;
  filteredUnits: AbcProductUnity[];
  handleSortUnity(index: string): void;
  filteredBranches: AbcProductBranch[];
  handleSortBranch(index: string): void;
  filteredCategories: AbcProductCategory[];
  handleSortCategory(index: string): void;
  filteredSubgroups: AbcProductSubgroup[];
  handleSortSubgroup(index: string): void;
  filteredSellers: AbcProductSeller[];
  handleSortSeller(index: string): void;
  filteredPayments: AbcProductPayment[];
  handleSortPayment(index: string): void;
  filteredAgroupedPayments: AgroupedAbcProductPayment[];
  handleSortAgroupedPayment(index: string): void;
  filteredCustomers: AbcProductCustomer[];
  handleSortCustomer(index: string): void;
  moreSeller: AbcProductSeller | null;
  setMoreSeller: Dispatch<SetStateAction<AbcProductSeller | null>>;
  filteredManufacturers: AbcProductManufacturer[];
  handleSortManufacturer(index: string): void;
  moreManufacturer: AbcProductManufacturer | null;
  setMoreManufacturer: Dispatch<SetStateAction<AbcProductManufacturer | null>>;
  displayMode: 'list' | 'module';
  setDisplayMode: Dispatch<SetStateAction<'list' | 'module'>>;
  searchText: string;
  handleSearch(searchValue: string): void;
  handleSearchInputChange(value: string): void;
  handleSearchSubmit(): void;
  loading: boolean;
  formattedTotal: AbcProductTotalProps;
  orderedIndex: OrderIndexData;
};

const AbcProductsContext = createContext<AbcProductsContextValue>({} as AbcProductsContextValue);

export function useAbcProducts(): AbcProductsContextValue {
  const context = useContext(AbcProductsContext);
  return context;
}

interface ProductProviderProps {
  filter: AbcProductFilterParams;
  children: React.ReactNode;
}

const ProductProvider: React.FC<ProductProviderProps> = ({ children, filter }) => {
  const { h2iApi } = useApp();
  const { windowWidth, isMobile, isOpenedMenu, handleOpenMenu } = useApp();
  const [orderedIndex, sort] = useTableOrder();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [units, setUnits] = useState<AbcProductUnity[]>([]);
  const [products, setProducts] = useState<AbcProduct[]>([]);
  const [sellers, setSellers] = useState<AbcProductSeller[]>([]);
  const [customers, setCustomers] = useState<AbcProductCustomer[]>([]);
  const [branches, setBranches] = useState<AbcProductBranch[]>([]);
  const [segments, setSegments] = useState<AbcProductSegment[]>([]);
  const [subgroups, setSubgroups] = useState<AbcProductSubgroup[]>([]);
  const [payments, setPayments] = useState<AbcProductPayment[]>([]);
  const [agroupedPayments, setAgroupedPayments] = useState<AgroupedAbcProductPayment[]>([]);
  const [categories, setCategories] = useState<AbcProductCategory[]>([]);
  const [moreProduct, setMoreProduct] = useState<AbcProduct | null>(null);
  const [moreUnity, setMoreUnity] = useState<AbcProductUnity | null>(null);
  const [filteredUnits, setFilteredUnits] = useState<AbcProductUnity[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<AbcProduct[]>([]);
  const [selectedSale, setSelectedSale] = useState<Sale | null>(null);
  const [moreSeller, setMoreSeller] = useState<AbcProductSeller | null>(null);
  const [moreBranch, setMoreBranch] = useState<AbcProductBranch | null>(null);
  const [filteredSellers, setFilteredSellers] = useState<AbcProductSeller[]>([]);
  const [filteredPayments, setFilteredPayments] = useState<AbcProductPayment[]>([]);
  const [filteredAgroupedPayments, setFilteredAgroupedPayments] = useState<AgroupedAbcProductPayment[]>([]);
  const [filteredCustomers, setFilteredCustomers] = useState<AbcProductCustomer[]>([]);
  const [moreSegment, setMoreSegment] = useState<AbcProductSegment | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<AbcProduct | null>(null);
  const [manufacturers, setManufacturers] = useState<AbcProductManufacturer[]>([]);
  const [selectedUnity, setSelectedUnity] = useState<AbcProductUnity | null>(null);
  const [filteredBranches, setFilteredBranches] = useState<AbcProductBranch[]>([]);
  const [filteredSegments, setFilteredSegments] = useState<AbcProductSegment[]>([]);
  const [moreSubgroup, setMoreSubgroup] = useState<AbcProductSubgroup | null>(null);
  const [selectedBranch, setSelectedBranch] = useState<AbcProductBranch | null>(null);
  const [filteredSubgroups, setFilteredSubgroups] = useState<AbcProductSubgroup[]>([]);
  const [selectedSegment, setSelectedSegment] = useState<AbcProductSegment | null>(null);
  const [filteredCategories, setFilteredCategories] = useState<AbcProductCategory[]>([]);
  const [selectedSubgroup, setSelectedSubgroup] = useState<AbcProductSubgroup | null>(null);
  const [moreManufacturer, setMoreManufacturer] = useState<AbcProductManufacturer | null>(null);
  const [filteredManufacturers, setFilteredManufacturers] = useState<AbcProductManufacturer[]>([]);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>(isMobile || windowWidth < 930 ? 'module' : 'list');
  const [formattedTotal, setFormattedTotal] = useState<AbcProductTotalProps>({
    total: 0,
    coust: 0,
    mc: 0,
    spiff: 0,
    unitary: 0,
    quantity: 0,
  });

  useEffect(() => {
    setDisplayMode(isMobile || windowWidth < 930 ? 'module' : 'list');
  }, [isMobile, windowWidth]);

  useEffect(() => {
    setFilteredManufacturers(manufacturers);
  }, [manufacturers]);

  useEffect(() => {
    setFilteredSellers(sellers);
  }, [sellers]);

  useEffect(() => {
    setFilteredCustomers(customers);
  }, [customers]);

  useEffect(() => {
    setFilteredUnits(units);
  }, [units]);

  useEffect(() => {
    setFilteredSegments(segments);
  }, [segments]);

  useEffect(() => {
    setFilteredPayments(payments);
  }, [payments]);

  useEffect(() => {
    setFilteredAgroupedPayments(agroupedPayments);
  }, [agroupedPayments]);

  useEffect(() => {
    setFilteredCategories(categories);
  }, [categories]);

  useEffect(() => {
    setFilteredProducts(products);
  }, [products]);

  useEffect(() => {
    setFilteredBranches(branches);
  }, [branches]);

  useEffect(() => {
    setFilteredSubgroups(subgroups);
  }, [subgroups]);

  const handleSearch = useCallback(
    (searchValue: string) => {
      if (!h2iApi) return;

      const formattedInitialDate = !filter.initialDate ? '' : format(filter.initialDate, 'P', { locale: ptBR });
      const formattedFinalDate = !filter.finalDate ? '' : format(filter.finalDate, 'P', { locale: ptBR });

      setLoading(true);

      h2iApi
        .get(`/api/relcurvaabcprodutos`, {
          params: {
            data_ini: formattedInitialDate,
            data_fim: formattedFinalDate,
            id_filial: filter.selectedBranchId || '',
            id_subgrupo: filter.subGroupIds.join(','),
            filtro: searchValue,
            cliente: filter.customer,
            tipo_venda: filter.typeSale,
            id_categoria: filter.categoryIds.join(','),
            id_subcategoria: filter.subCategoryIds.join(','),
            pedido_por: filter.madeBy,
            id_segmento: filter.segmentIds.join(','),
            id_unidade: filter.unitIds.join(','),
            id_fabricante: filter.manufacturerIds.join(','),
          },
        })
        .then(response => {
          const _response = response.data[0];
          const produtos: AbcProduct[] = _response.produtos;

          const total = produtos.reduce((sum, item) => sum + item.total, 0);
          const coust = produtos.reduce((sum, item) => sum + item.custo, 0);
          const mc = produtos.reduce((sum, item) => sum + item.mc, 0);
          const spiff = produtos.reduce((sum, item) => sum + item.spiff, 0);
          const unitary = produtos.reduce((sum, item) => sum + item.unitario, 0);
          const quantity = produtos.reduce((sum, item) => sum + item.qtd, 0);
          setFormattedTotal({
            total,
            coust,
            mc,
            spiff,
            quantity,
            unitary,
          });

          const _units = _response.unidade.map(item => {
            item.formattedTotal = moneyFormat(item.total);
            item.formattedCoust = moneyFormat(item.custo);
            item.formattedMc = moneyFormat(item.mc);
            item.formattedSpiff = moneyFormat(item.spiff);
            item.formattedUnitary = moneyFormat(item.unitario);
            item.formattedBilling = percentFormat((item.total / total) * 100);
            item.billing = (item.total / total) * 100;

            return item;
          });

          setUnits(_units);

          const _segments = _response.segmento.map(item => {
            item.formattedTotal = moneyFormat(item.total);
            item.formattedCoust = moneyFormat(item.custo);
            item.formattedMc = moneyFormat(item.mc);
            item.formattedSpiff = moneyFormat(item.spiff);
            item.formattedUnitary = moneyFormat(item.unitario);
            item.formattedBilling = percentFormat((item.total / total) * 100);
            item.billing = (item.total / total) * 100;

            return item;
          });

          setSegments(_segments);

          const _sellers = _response.pedido_por.map(item => {
            item.formattedTotal = moneyFormat(item.total);
            item.formattedCoust = moneyFormat(item.custo);
            item.formattedMc = moneyFormat(item.mc);
            item.formattedSpiff = moneyFormat(item.spiff);
            item.formattedUnitary = moneyFormat(item.unitario);
            item.formattedBilling = percentFormat((item.total / total) * 100);
            item.billing = (item.total / total) * 100;

            return item;
          });

          setSellers(_sellers);

          const _customers = _response.cliente.map(item => {
            item.formattedTotal = moneyFormat(item.total);
            item.formattedCoust = moneyFormat(item.custo);
            item.formattedMc = moneyFormat(item.mc);
            item.formattedSpiff = moneyFormat(item.spiff);
            item.formattedUnitary = moneyFormat(item.unitario);
            item.formattedBilling = percentFormat((item.total / total) * 100);
            item.billing = (item.total / total) * 100;

            return item;
          });

          setCustomers(_customers);

          const _manufacturers = _response.fabricante.map(item => {
            item.formattedTotal = moneyFormat(item.total);
            item.formattedCoust = moneyFormat(item.custo);
            item.formattedMc = moneyFormat(item.mc);
            item.formattedSpiff = moneyFormat(item.spiff);
            item.formattedUnitary = moneyFormat(item.unitario);
            item.formattedBilling = percentFormat((item.total / total) * 100);
            item.billing = (item.total / total) * 100;

            return item;
          });

          setManufacturers(_manufacturers);

          const _categories = _response.categoria.map(item => {
            item.formattedTotal = moneyFormat(item.total);
            item.formattedCoust = moneyFormat(item.custo);
            item.formattedMc = moneyFormat(item.mc);
            item.formattedSpiff = moneyFormat(item.spiff);
            item.formattedUnitary = moneyFormat(item.unitario);
            item.formattedBilling = percentFormat((item.total / total) * 100);
            item.billing = (item.total / total) * 100;

            return item;
          });

          setCategories(_categories);

          const _subgroups = _response.subgrupo.map(item => {
            item.formattedTotal = moneyFormat(item.total);
            item.formattedCoust = moneyFormat(item.custo);
            item.formattedMc = moneyFormat(item.mc);
            item.formattedSpiff = moneyFormat(item.spiff);
            item.formattedUnitary = moneyFormat(item.unitario);
            item.formattedBilling = percentFormat((item.total / total) * 100);
            item.billing = (item.total / total) * 100;

            return item;
          });

          setSubgroups(_subgroups);

          const _payments = _response.pagamentos.map(item => {
            item.formattedValue = moneyFormat(item.valor);

            return item;
          });

          setPayments(_payments);

          const paymentMap = new Map();

          _response.pagamentos.forEach(item => {
            if (!paymentMap.has(item.forma_pagamento)) {
              paymentMap.set(item.forma_pagamento, {
                forma_pagamento: item.forma_pagamento,
                valor: 0,
              } as AgroupedAbcProductPayment);
            }

            const entry: AgroupedAbcProductPayment = paymentMap.get(item.forma_pagamento);
            entry.valor += item.valor;
            entry.formattedValue = moneyFormat(entry.valor);
          });

          setAgroupedPayments(Array.from(paymentMap.values()));

          const _branches = _response.filial.map(item => {
            item.formattedTotal = moneyFormat(item.total);
            item.formattedCoust = moneyFormat(item.custo);
            item.formattedMc = moneyFormat(item.mc);
            item.formattedSpiff = moneyFormat(item.spiff);
            item.formattedUnitary = moneyFormat(item.unitario);
            item.formattedBilling = percentFormat((item.total / total) * 100);
            item.billing = (item.total / total) * 100;

            return item;
          });

          setBranches(_branches);

          const _products = produtos.map(item => {
            item.formattedTotal = moneyFormat(item.total);
            item.formattedCoust = moneyFormat(item.custo);
            item.formattedMc = moneyFormat(item.mc);
            item.formattedSpiff = moneyFormat(item.spiff);
            item.formattedUnitary = moneyFormat(item.unitario);
            item.formattedBilling = percentFormat((item.total / total) * 100);
            item.billing = (item.total / total) * 100;

            return item;
          });

          setProducts(_products);
        })
        .catch(err => console.error(err))
        .finally(() => {
          setLoading(false);
        });
    },
    [filter, h2iApi],
  );

  function handleSortPayment(index: string) {
    const filtered = sort(index, filteredPayments);
    setFilteredPayments(filtered);
  }

  function handleSortAgroupedPayment(index: string) {
    const filtered = sort(index, filteredAgroupedPayments);
    setFilteredAgroupedPayments(filtered);
  }

  function handleSortSeller(index: string) {
    const filtered = sort(index, filteredSellers);
    setFilteredSellers(filtered);
  }

  function handleSortCustomer(index: string) {
    const filtered = sort(index, filteredCustomers);
    setFilteredCustomers(filtered);
  }

  function handleSortManufacturer(index: string) {
    const filtered = sort(index, filteredManufacturers);
    setFilteredManufacturers(filtered);
  }

  function handleSortUnity(index: string) {
    const filtered = sort(index, filteredUnits);
    setFilteredUnits(filtered);
  }

  function handleSortSegment(index: string) {
    const filtered = sort(index, filteredSegments);
    setFilteredSegments(filtered);
  }

  function handleSortCategory(index: string) {
    const filtered = sort(index, filteredCategories);
    setFilteredCategories(filtered);
  }

  function handleSortBranch(index: string) {
    const filtered = sort(index, filteredBranches);
    setFilteredBranches(filtered);
  }

  function handleSortSubgroup(index: string) {
    const filtered = sort(index, filteredSubgroups);
    setFilteredSubgroups(filtered);
  }

  function handleSort(index: string) {
    const filtered = sort(index, filteredProducts);
    setFilteredProducts(filtered);
  }

  function handleSearchSubmit(event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    if (isOpenedMenu) {
      handleOpenMenu();
    }

    handleSearch(searchText);
  }

  function handleSearchInputChange(value: string) {
    setSearchText(value);
  }

  return (
    <AbcProductsContext.Provider
      value={{
        filteredPayments,
        handleSortPayment,
        filteredAgroupedPayments,
        handleSortAgroupedPayment,
        setSelectedSale,
        moreUnity,
        setMoreUnity,
        selectedUnity,
        setSelectedUnity,
        moreSegment,
        setMoreSegment,
        selectedSegment,
        setSelectedSegment,
        moreSubgroup,
        setMoreSubgroup,
        selectedSubgroup,
        setSelectedSubgroup,
        moreBranch,
        setMoreBranch,
        selectedBranch,
        moreProduct,
        setSelectedBranch,
        setMoreProduct,
        selectedProduct,
        setSelectedProduct,
        displayMode,
        setDisplayMode,
        filteredBranches,
        handleSortBranch,
        filteredCategories,
        handleSortCategory,
        filteredProducts,
        handleSort,
        filteredSegments,
        handleSortSegment,
        filteredSubgroups,
        handleSortSubgroup,
        filteredUnits,
        handleSortUnity,
        filteredSellers,
        handleSortSeller,
        filteredCustomers,
        handleSortCustomer,
        moreSeller,
        setMoreSeller,
        filteredManufacturers,
        handleSortManufacturer,
        moreManufacturer,
        setMoreManufacturer,
        formattedTotal,
        handleSearch,
        handleSearchInputChange,
        handleSearchSubmit,
        loading,
        orderedIndex,
        searchText,
        selectedSale,
      }}
    >
      {children}
    </AbcProductsContext.Provider>
  );
};

export default ProductProvider;
