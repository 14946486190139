import React, { useEffect, useCallback, useState } from 'react';
import InsideLoading from 'components/loading/InsideLoading';
import { BsInfoCircle } from 'react-icons/bs';
import { moneyFormat } from 'helpers/numberFormat';
import { FaCopy, FaSync } from 'react-icons/fa';
import InsideSaving from 'components/loading/InsideSaving';
import { useMessaging } from 'hooks/messaging';
import Dialog from 'components/dialogs/Dialog';
import { Button, styled, Typography } from '@mui/material';
import { useApp } from 'hooks/app';
import { Pix, WhatsApp } from '@mui/icons-material';
import PhoneChange from './PhoneChange';

const Container = styled('div')({
  maxWidth: 800,
  width: '100%',
  margin: '0 auto',
  padding: 15,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
});

const Actions = styled('div')({
  display: 'grid',
  gap: 5,
  marginTop: 20,
  '& > button': {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    justifyContent: 'center',
  },
  '& > .payment': {
    color: '#fff',
  },
});

const Content = styled('div')({
  maxWidth: 600,
  margin: '0 auto',
  '& > .number': {
    display: 'grid',
    marginTop: 20,
    gap: 5,
  },
});

const Code = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 50px',
  alignItems: 'center',
  padding: 5,
  borderRadius: 5,
  border: '1px solid #ddd',
  backgroundColor: '#f5f5f5',
  '& > .code': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 11,
  },
  '& > .copy': {
    cursor: 'pointer',
    fontSize: 12,
    color: theme.palette.primary.main,
    transition: 'all 0.1s',
    '&:hover': {
      color: theme.palette.primary.dark,
      fontWeight: 'bold',
    },
  },
}));

const QrCodeImage = styled('img')({
  width: 200,
  height: 200,
  justifySelf: 'center',
});

const Header = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #ddd',
});

const Timer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: 10,
  color: '#666',
  fontSize: 14,
  backgroundColor: '#f5f5f5',
  padding: 5,
  '& > svg': {
    marginRight: 5,
  },
});

const Payment = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: 10,
  fontSize: 14,
  padding: 5,
  gap: 10,
});

const Steps = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 10,
  textAlign: 'left',
  marginTop: 20,
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

const StepsText = styled('div')({
  display: 'grid',
  gap: 10,
  '& > p': {
    color: '#898989',
    fontSize: 14,
  },
});

const ImageContainer = styled('div')({
  display: 'grid',
  gap: 10,
  '& > img': {
    justifySelf: 'center',
  },
});

interface GeneratePixProps {
  onExited(): void;
  branchId: string;
  ticketId: string;
  customerDocument: string;
  total: number;
  phone: string;
  customerName: string;
  invoiceNumber: string;
  issueDate: string;
  dueDate: string;
}

interface PixProps {
  id_pix: string;
  payload: string;
  qrcode: string;
  result: string;
  data: string;
  hora: string;
  guid: string;
}

const GeneratePix: React.FC<GeneratePixProps> = ({
  onExited,
  customerName,
  branchId,
  phone: originalPhone,
  ticketId,
  total,
  customerDocument,
  invoiceNumber,
  issueDate,
  dueDate,
}) => {
  const { h2iApi } = useApp();
  const { handleOpen } = useMessaging();
  const [copied, setCopied] = useState(false);
  const [copiedLink, setCopiedLink] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pix, setPix] = useState<PixProps | null>(null);
  const [timeLeft, setTimeLeft] = useState('00:00');
  const [consulting, setConsulting] = useState(false);
  const [dialogWhatsapp, setDialogWhatsapp] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  useEffect(() => {
    if (copiedLink) {
      setTimeout(() => {
        setCopiedLink(false);
      }, 3000);
    }
  }, [copiedLink]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (!pix?.hora) return setTimeLeft('00:00');
      const [hours, minutes, seconds] = pix.hora.split(':').map(Number);
      const pixTime = new Date();
      pixTime.setHours(hours, minutes, seconds);

      const timePassed = (Date.now() - pixTime.getTime()) / 1000;
      const remainingTime = Math.max(60 * 60 - timePassed, 0);
      const minutesLeft = Math.floor(remainingTime / 60);
      const secondsLeft = Math.floor(remainingTime % 60);
      setTimeLeft(`${minutesLeft.toString().padStart(2, '0')}:${secondsLeft.toString().padStart(2, '0')}`);

      if (remainingTime <= 0) clearInterval(timer);
    }, 1000);

    return () => clearInterval(timer);
  }, [pix]);

  const handleSendWhatsApp = useCallback(
    (phone: string) => {
      setDialogWhatsapp(false);
      if (!pix) return;
      const msg = `
        Olá, *${customerName}*! %0A%0ASegue o link para o pagamento do seu boleto via PIX:%0A%0Ahttps://www.h2i.com.br/pix-qrcode-print?guid=${
        pix.guid
      }%0A%0ADetalhes do pagamento:%0A
        - *Valor:* ${moneyFormat(total)}%0A
        - *Nota Fiscal:* ${invoiceNumber}%0A
        - *Emissão:* ${issueDate}%0A
        - *Vencimento:* ${dueDate}%0A%0AO pagamento é simples e rápido! Basta clicar no link e seguir as instruções.%0A%0AA confirmação e baixa do boleto serão feitas de forma automática!%0A%0ACaso tenha alguma dúvida, estou à disposição.%0A%0AAtenciosamente,%0AEquipe H2i`;

      window.open(`https://api.whatsapp.com/send?phone=${phone}&text=${msg}`);
    },
    [pix, customerName, total, invoiceNumber, issueDate, dueDate],
  );

  const handleSearch = useCallback(
    (branchId: string, ticketId: string, customerDocument: string) => {
      if (!h2iApi) return;
      setLoading(true);
      h2iApi
        .get('/api/getpixboleto', {
          params: {
            id_filial: branchId,
            id: ticketId,
            cnpj: customerDocument,
          },
        })
        .then(response => {
          if (response.data.result === 'ATIVA') {
            setPix(response.data);
            return;
          }
          if (response.data.result === '0') {
            handleOpen('Ocorreu um erro ao gerar o código PIX. Tente novamente mais tarde.');
          }
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [h2iApi, handleOpen],
  );

  useEffect(() => {
    handleSearch(branchId, ticketId, customerDocument);
  }, [branchId, ticketId, customerDocument, handleSearch]);

  async function handleCopyToClipboard() {
    if (!pix) return;
    await navigator.clipboard.writeText(pix.payload);
    setCopied(true);
  }

  function handleConfirmPayment() {
    if (!h2iApi) return;
    setConsulting(true);
    h2iApi
      .get('/api/getconsultapagamentopix', {
        params: {
          guid: pix?.guid,
        },
      })
      .then(response => {
        if (response.data.result === 'pendente') {
          handleOpen('Pagamento ainda não foi confirmado. Aguarde alguns instantes e tente novamente.');
          return;
        }
        handleOpen('Pagamento confirmado com sucesso!');
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setConsulting(false);
      });
  }

  async function handleCopyLinkToClipboard() {
    if (!pix) return;
    await navigator.clipboard.writeText(`https://www.h2i.com.br/pix-qrcode-print?guid=${pix.guid}`);
    setCopiedLink(true);
  }

  return (
    <Dialog onExited={onExited} title={`copie o pix para pagar`}>
      {dialogWhatsapp && (
        <PhoneChange
          handleSendWhatsApp={handleSendWhatsApp}
          onExited={() => setDialogWhatsapp(false)}
          originalPhone={originalPhone}
        />
      )}
      <Container>
        {consulting && <InsideSaving />}
        {loading ? (
          <div>
            <InsideLoading />
          </div>
        ) : (
          <>
            {pix && (
              <Content>
                <Header>
                  <Timer>
                    <BsInfoCircle />
                    <Typography>Seu código expira em: {timeLeft}</Typography>
                  </Timer>
                  <Payment>
                    <Pix fontSize="large" htmlColor="#42b3b3" />
                    <Typography fontSize={16}>
                      Pague <b>{moneyFormat(total)}</b> com PIX
                    </Typography>
                  </Payment>
                </Header>

                <Steps>
                  <StepsText>
                    <Typography color="#898989">1. Abra o aplicativo do seu banco e entre na opção PIX</Typography>
                    <Typography color="#898989">
                      2. Selecione a opção de pagamento por <strong>QR CODE</strong>
                    </Typography>
                    <Typography color="#898989">
                      3. Escaneie o <strong>QR CODE</strong> abaixo
                    </Typography>
                  </StepsText>

                  <ImageContainer>
                    <QrCodeImage src={pix.qrcode} alt="qrcode para pagamento pedido" />
                    <Typography color="#666" fontSize={12}>
                      * o PIX estará disponível durante uma hora.
                    </Typography>
                  </ImageContainer>
                </Steps>

                <div className="number">
                  <Typography>ou se preferir, copie o código abaixo e cole no aplicativo do seu banco</Typography>
                  <Code>
                    <Typography className="code">{pix.payload}</Typography>
                    <Typography className="copy" onClick={handleCopyToClipboard}>
                      copiar
                    </Typography>
                  </Code>
                </div>

                <Actions>
                  {copied ? (
                    <Button variant="contained" color="primary">
                      copiado!
                    </Button>
                  ) : (
                    <Button variant="contained" color="primary" onClick={handleCopyToClipboard}>
                      <FaCopy /> copiar código PIX
                    </Button>
                  )}

                  {copiedLink ? (
                    <Button variant="contained" color="warning">
                      copiado!
                    </Button>
                  ) : (
                    <Button variant="contained" color="warning" onClick={handleCopyLinkToClipboard}>
                      <FaCopy /> copiar link
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={consulting}
                    className={consulting ? 'consulting payment' : 'payment'}
                    onClick={handleConfirmPayment}
                  >
                    <FaSync /> já efetuei o pagamento
                  </Button>
                  <Button variant="contained" color="success" onClick={() => setDialogWhatsapp(true)}>
                    <WhatsApp /> enviar para whatsapp
                  </Button>
                  <Button variant="text" onClick={onExited}>
                    fechar
                  </Button>
                </Actions>
              </Content>
            )}
          </>
        )}
      </Container>
    </Dialog>
  );
};

export default GeneratePix;
