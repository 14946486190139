import { Button, styled, TextField } from '@mui/material';
import DialogInput from 'components/dialogs/DialogInput';
import React from 'react';
import { FinancialTicket } from 'types/financialReportList';

interface Props {
  onExited(): void;
  onSave(): void;
  selectedTicket: FinancialTicket;
  onChange(index: keyof FinancialTicket, value: any): void;
}

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  maxWidth: 300,
  margin: '0 auto',
  gap: 10,
  flexDirection: 'column',
});

const FinancialTicketMenuDialog: React.FC<Props> = ({ onExited, onSave, onChange, selectedTicket }) => {
  return (
    <DialogInput maxWidth="sm" hideBackdrop={true} onExited={onExited}>
      <Container>
        <TextField
          value={selectedTicket.email}
          onChange={e => onChange('email', e.target.value)}
          label="E-mail"
          placeholder="Digite o e-mail do cliente"
        />

        <Button variant="contained" onClick={onSave}>
          Enviar
        </Button>
      </Container>
    </DialogInput>
  );
};

export default FinancialTicketMenuDialog;
