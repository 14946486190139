import { Button, Typography, lighten, styled } from '@mui/material';
import React from 'react';
import { MdArrowBack } from 'react-icons/md';
import history from 'services/history';
import { useDavReviewDetail } from './hooks/useDavReviewDetail';
import { useApp } from 'hooks/app';

const Column = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 5,
  '&.header': {
    gap: 0,
    flexDirection: 'column',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const Flex = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const PaymentStatus = styled(Typography)(({ theme }) => ({
  display: 'flex',
  padding: 7,
  color: '#fff',
  borderRadius: 4,
  fontWeight: 500,
  height: '100%',
  alignItems: 'center',
  textAlign: 'center',
  lineHeight: 1.75,
  fontSize: '0.875rem',
  '&.paymentNotConfirmed': {
    backgroundColor: lighten(theme.palette.error.main, 0.1),
  },
  '&.paymentConfirmed': {
    backgroundColor: lighten(theme.palette.success.main, 0.1),
  },
}));

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 30,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: 10,
  },
}));

const BackLink = styled('a')({
  cursor: 'pointer',
  display: 'inline-flex',
  alignItems: 'center',
  '& svg': {
    marginRight: 15,
  },
});

const CustomTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.main,
  fontWeight: 'bold',
}));

const Shipment = styled('div')({
  margin: '40px 0 20px',
  paddingBottom: 20,
  display: 'flex',
  gap: '5px',
  width: '100%',
  alignItems: 'center',
  borderBottom: '1px dashed #eee',
});

const DavReviewDetailHeader: React.FC = () => {
  const { windowWidth } = useApp();
  const { order, handleClick } = useDavReviewDetail();
  return (
    <>
      <BackLink onClick={() => history.push(`/review/dav/${order.id_filial}`)}>
        <MdArrowBack fontSize={20} /> voltar
      </BackLink>
      <Header>
        <Flex>
          <Column className="header">
            <Typography fontSize={16} fontWeight="bold">
              pedido {order.id}
            </Typography>
            <Typography fontSize={12} color="#666">
              emitido em {order.formattedCreatedAt}
            </Typography>
          </Column>

          {windowWidth < 600 ? (
            order.pago ? (
              <PaymentStatus color="#fff" fontSize={11} className="paymentConfirmed">
                pagamento confirmado
              </PaymentStatus>
            ) : (
              <PaymentStatus color="#fff" fontSize={11} className="paymentNotConfirmed">
                aguardando pagamento
              </PaymentStatus>
            )
          ) : (
            <></>
          )}
        </Flex>

        <Column>
          <Button
            color={
              order && order.mcPercent
                ? order.mcPercent > 15
                  ? 'success'
                  : order.mcPercent < 5
                  ? 'error'
                  : 'warning'
                : 'error'
            }
            onClick={handleClick}
            variant="contained"
          >
            concessão de parcelamento
          </Button>

          {order.pagamento.some(element => element.tipo === 'BOL') && (
            <Button color="warning" variant="contained">
              autorizar faturamento de boleto
            </Button>
          )}
          {windowWidth >= 600 ? (
            order.pago ? (
              <PaymentStatus className="paymentConfirmed">pagamento confirmado</PaymentStatus>
            ) : (
              <PaymentStatus className="paymentNotConfirmed">aguardando pagamento</PaymentStatus>
            )
          ) : (
            <></>
          )}
        </Column>
      </Header>

      <Shipment>
        <Typography fontSize={20}>método de entrega</Typography>
        <CustomTypography>{order.modalidade_entrega}</CustomTypography>
      </Shipment>
    </>
  );
};

export default DavReviewDetailHeader;
