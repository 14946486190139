import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import { FinancialTicket } from 'types/financialReportList';
import FinancialTicketMenuDialog from './FinancialTicketMenuDialog';
import { parsePtBRDate } from 'helpers/parsePtBRDate';
import { useApp } from 'hooks/app';
import InsideSaving from 'components/loading/InsideSaving';
import GeneratePix from './dialog/GeneratePix';

type FinancialTicketMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
  selectedTicket: FinancialTicket | null;
};

const FinancialTicketMenu: React.FC<FinancialTicketMenuProps> = ({
  anchorEl,
  setAnchorEl,
  selectedTicket: _selectedTicket,
}) => {
  const [selectedTicket, setSelectedTicket] = useState<FinancialTicket | null>(null);
  const [dialog, setDialog] = useState<false | 'reminder' | 'warning'>(false);
  const [saving, setSaving] = useState(false);
  const [generatePix, setGeneratePix] = useState(false);
  const { handleOpen } = useMessaging();
  const { h2iApi } = useApp();

  useEffect(() => {
    setSelectedTicket(_selectedTicket);
  }, [_selectedTicket]);

  function printTicket() {
    if (!selectedTicket?.url) {
      handleOpen('Boleto não disponível');
      return;
    }
    window.open(selectedTicket?.url);

    setAnchorEl(null);
  }

  function sendReminder() {
    if (!selectedTicket?.url) {
      handleOpen('Boleto não disponível');
      return;
    }

    const data = {
      emails: [selectedTicket?.email],
      ticket_link: selectedTicket?.url,
      ticket_id: selectedTicket?.id,
      base_template_id: 15,
      street: '.',
      neighborhood: '.',
      city: '.',
      state: 'Bahia',
      postal_code: '.',
      phone: '.',
      due_date: selectedTicket.vencimento,
      company_name: 'Eltecno Distribuição de Informática LTDA',
    };

    setSaving(true);

    api
      .post('/email-templates/14/__send', data)
      .then(() => {
        handleOpen('Boleto enviado com sucesso');

        if (h2iApi) {
          h2iApi.post('/api/savelembreteemailfinanceiro', {
            email: [
              {
                id: selectedTicket.id,
                id_filial: selectedTicket.id_filial,
              },
            ],
          });
        }
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setAnchorEl(null);
        setDialog(false);
        setSaving(false);
      });
  }

  function sendWarning() {
    if (!selectedTicket?.url) {
      handleOpen('Boleto não disponível');
      return;
    }

    const data = {
      emails: [selectedTicket?.email],
      ticket_link: selectedTicket?.url,
      ticket_id: selectedTicket?.id,
      base_template_id: 15,
      street: '.',
      neighborhood: '.',
      city: '.',
      state: 'Bahia',
      postal_code: '.',
      phone: '.',
      due_date: selectedTicket.vencimento,
      company_name: 'Eltecno Distribuição de Informática LTDA',
    };

    setSaving(true);

    api
      .post('/email-templates/16/__send', data)
      .then(() => {
        handleOpen('Boleto enviado com sucesso');

        if (h2iApi) {
          h2iApi.post('/api/saveavisoemailfinanceiro', {
            email: [
              {
                id: selectedTicket.id,
                id_filial: selectedTicket.id_filial,
              },
            ],
          });
        }
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setAnchorEl(null);
        setDialog(false);
        setSaving(false);
      });
  }

  function onChange(index: keyof FinancialTicket, value: any) {
    setSelectedTicket(prev => (prev ? { ...prev, [index]: value } : null));
  }

  function onSave() {
    if (dialog === 'reminder') {
      sendReminder();
    } else if (dialog === 'warning') {
      sendWarning();
    }
  }
  function sendMessage() {
    if (selectedTicket) {
      const text = `Olá, *${
        selectedTicket?.cliente
      }*!%0A%0AConforme solicitado, segue o link contendo o boleto para pagamento%3A %0A%0A${encodeURIComponent(
        selectedTicket?.url,
      )}. %0A%0ASe precisar de alguma ajuda ou mais informações, estamos à disposição!%0A%0A*Grupo H2i*`;
      window.open(`https://api.whatsapp.com/send?phone=55${selectedTicket?.telefone}&text=${text}`);
    }
  }
  const isTicketExpired = selectedTicket ? parsePtBRDate(selectedTicket?.vencimento) < new Date() : false;

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      {saving && <InsideSaving />}
      {generatePix && selectedTicket && (
        <GeneratePix
          invoiceNumber={selectedTicket.numero_nf}
          dueDate={selectedTicket.vencimento}
          issueDate={selectedTicket.data_lanc}
          customerName={selectedTicket.cliente}
          phone={selectedTicket.telefone}
          total={selectedTicket.valor_total}
          branchId={selectedTicket.id_filial}
          onExited={() => setGeneratePix(false)}
          customerDocument={selectedTicket.cnpj_cpf}
          ticketId={selectedTicket.id}
        />
      )}
      {dialog && selectedTicket && (
        <FinancialTicketMenuDialog
          onExited={() => setDialog(false)}
          onSave={onSave}
          selectedTicket={selectedTicket}
          onChange={onChange}
        />
      )}
      <MenuItem onClick={printTicket}>Imprimir boleto</MenuItem>
      <MenuItem onClick={() => setDialog('reminder')}>Enviar lembrete de vencimento</MenuItem>
      <MenuItem onClick={sendMessage}>Enviar lembrete de vencimento (WhatsApp)</MenuItem>
      <MenuItem disabled={selectedTicket?.pago === 'S'} onClick={() => setGeneratePix(true)}>
        Gerar PIX
      </MenuItem>
      <MenuItem onClick={() => setDialog('warning')} disabled={!isTicketExpired}>
        Enviar aviso de débito
      </MenuItem>
    </Menu>
  );
};

export default FinancialTicketMenu;
