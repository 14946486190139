import { styled, Typography } from '@mui/material';
import Dialog from 'components/dialogs/Dialog';
import React from 'react';
import { FinancialTicket } from 'types/financialReportList';

interface Props {
  failedTickets: FinancialTicket[];
  onExited(): void;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
});

const FailedSendTicketsDialog: React.FC<Props> = ({ failedTickets, onExited }) => {
  return (
    <Dialog onExited={onExited} maxWidth="sm" title="Boletos não enviados">
      <Container>
        <Typography variant="body2" align="center">
          Não foi possível enviar os seguintes boletos:
        </Typography>

        <ul>
          {failedTickets.map(ticket => (
            <li key={ticket.id}>
              <Typography variant="caption">
                <strong>#{ticket.id}</strong>: {ticket.cnpj_cpf} - {ticket.cliente}
              </Typography>
            </li>
          ))}
        </ul>
      </Container>
    </Dialog>
  );
};

export default FailedSendTicketsDialog;
