import React from 'react';
import {
  LocalizationProvider,
  DesktopDatePickerProps,
  DesktopDatePicker as NativeDesktopDatePicker,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { pt } from 'date-fns/locale';
import { TextFieldProps, styled } from '@mui/material';

interface CustomKeyboardDatePickerProps extends DesktopDatePickerProps<any> {
  value: any;
  onChange(date: any): void;
  label?: string;
  textFieldProps?: TextFieldProps;
}

const CustomNativeDesktopDatePicker = styled(NativeDesktopDatePicker)({
  '& button': {
    padding: 0,
  },
  '& input': {
    paddingRight: '0px !important',
  },
});

const DesktopDatePicker: React.FC<any> = ({ value, onChange, label, textFieldProps, slotProps, ...rest }) => {
  return (
    <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
      <CustomNativeDesktopDatePicker
        slotProps={{ ...slotProps, textField: textFieldProps }}
        openTo="day"
        label={label}
        value={value}
        onChange={onChange}
        {...rest}
      />
    </LocalizationProvider>
  );
};

export default DesktopDatePicker;
