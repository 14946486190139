import React, { Dispatch, SetStateAction } from 'react';
import { FinancialTicket } from 'types/financialReportList';
import TicketItemModule from './TicketItemModule';
import { usePagination } from 'hooks/pagination';
import { List } from '@mui/material';

type TicketListModuleProps = {
  tickets: FinancialTicket[];
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
  setSelectedTicket(ticket: FinancialTicket): void;
};

const TicketListModule: React.FC<TicketListModuleProps> = ({ tickets, setAnchorEl, setSelectedTicket }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <List>
      {tickets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(ticket => (
        <TicketItemModule
          setSelectedTicket={setSelectedTicket}
          setAnchorEl={setAnchorEl}
          key={ticket.id}
          ticket={ticket}
        />
      ))}
    </List>
  );
};

export default TicketListModule;
