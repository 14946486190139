import React, { Dispatch, SetStateAction } from 'react';
import { Typography, Grid, styled, FormControlLabel, Checkbox } from '@mui/material';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import { FinancialTicket } from 'types/financialReportList';
import { ticketTableTemplate } from '../../ticketTableTemplate';
import { useFinancialTicket } from '../../hooks/useFinancialTicket';
import TicketItemTable from './TicketItemTable';
import TableContainer from 'components/table/TableContainer';

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
  '&.headerItemNumeric': {
    justifyContent: 'flex-end',
  },
});

type TicketListTableProps = {
  tickets: FinancialTicket[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
  selectAll: boolean;
  handleSelectAll(): void;
  selectedTickets: FinancialTicket[];
  handleToggle(ticket: FinancialTicket): void;
};

const TicketListTable: React.FC<TicketListTableProps> = ({
  tickets,
  selectAll,
  handleSelectAll,
  setAnchorEl,
  handleSort,
  orderedIndex,
  selectedTickets,
  handleToggle,
}) => {
  const { rowsPerPage, page } = usePagination();
  const { setSelectedTicket } = useFinancialTicket();

  function handleClick(tickets: FinancialTicket) {
    setSelectedTicket(tickets);
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer tableTemplate={ticketTableTemplate}>
          <TableContent useMinHeight={false}>
            <TableHeader>
              {ticketTableTemplate.map(item =>
                item.id === 'select' ? (
                  <HeaderItem style={{ justifyContent: 'center' }} key={item.id}>
                    <FormControlLabel
                      style={{ marginRight: 0 }}
                      label=""
                      key={item.id}
                      control={<Checkbox checked={selectAll} onChange={handleSelectAll} />}
                    />
                  </HeaderItem>
                ) : (
                  <HeaderItem
                    className={item.dataType === 'number' ? 'headerItemNumeric' : ''}
                    key={item.id}
                    onClick={() => handleSort(item.originalId)}
                  >
                    <Typography variant="caption" color="primary">
                      {item.description}
                    </Typography>
                    {orderedIndex.index === item.originalId &&
                      (orderedIndex.direction === 'asc' ? (
                        <ArrowUpward color="primary" />
                      ) : (
                        <ArrowDownward color="primary" />
                      ))}
                  </HeaderItem>
                ),
              )}
            </TableHeader>
            <TableBody useMaxHeight={true} maxHeight={370}>
              {tickets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(ticket => (
                <TableRow onClick={() => handleClick(ticket)} key={ticket.id}>
                  <TicketItemTable
                    handleToggle={handleToggle}
                    selectedTickets={selectedTickets}
                    setAnchorEl={setAnchorEl}
                    ticket={ticket}
                  />
                </TableRow>
              ))}
            </TableBody>
          </TableContent>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default TicketListTable;
