import React, { useState, useEffect, useMemo } from 'react';
import Routes from 'routes/Routes';
import AppContext, { AppContextData } from 'hooks/app';
import { useAuth } from 'hooks/auth';
import { ThemeProvider } from '@mui/material';
import { theme } from 'config/theme';
import MessagingProvider from 'hooks/messaging';
import SplashScreen from 'components/loading/SplashScreen';
import { api } from 'services/api';
import { useDispatch } from 'react-redux';
import { setSettings } from 'store/modules/settings/actions';
import { useSelector } from 'store/selector';
import axios from 'axios';
import PermissionRulesProvider from 'hooks/permissionRules';
import { setBranches } from 'store/modules/branches/actions';
import ProductSearchProvider from 'providers/ProductSearchProvider';
import { BrowserRouter } from 'routes/BrowserRouter';
import history from 'services/history';
import BranchesProvider from 'providers/BranchesProviders';
import { Branch } from 'types/branch';
import { useWindowSize } from 'hooks/windowSize';
import ErrorHandlerProvider from 'providers/error-handler/error-handler';

const App: React.FC = () => {
  const { isMobile, width: windowWidth } = useWindowSize();
  const [defaultBranches, setDefaultBranches] = useState<Branch[]>([]);
  const [accountingBranches, setAccountingBranches] = useState<Branch[]>([]);
  const [financialBranches, setFinancialBranches] = useState<Branch[]>([]);
  const [isOpenedMenu, setIsOpenedMenu] = useState(!isMobile && windowWidth > 1280);
  const { logout, checkAuth } = useAuth();
  const [loading, setLoading] = useState(checkAuth());
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const settings = useSelector(state => state.settings);

  const h2iApi = useMemo(() => {
    if (!settings)
      return axios.create({
        baseURL: 'https://host.h2i.com.br:9097',
        // baseURL: 'http://casaruy.ddns.net:9097',
        // baseURL: 'https://host.h2i.com.br:9096',
      });

    return axios.create({
      baseURL: settings.api_host,
      // baseURL: 'http://casaruy.ddns.net:9097',
      // baseURL: 'https://host.h2i.com.br:9096',
    });
  }, [settings]);

  const financialApi = useMemo(() => {
    return axios.create({
      // baseURL: 'http://179.67.215.1:9097/api',
      baseURL: 'https://host.h2i.com.br:9099/api',
    });
  }, []);

  useEffect(() => {
    if (!user) return;

    api.get('/settings').then(response => {
      dispatch(setSettings(response.data));
    });
  }, [dispatch, user]);

  useEffect(() => {
    if (!h2iApi) {
      return;
    }

    if (!financialApi) {
      return;
    }

    const accountingBranchesRequest = financialApi.get('/getfiliaisfinanceiro?conta_totalizadora=s');

    const financialBranchesRequest = financialApi.get('/filiais?grupoH2i=s');

    const defaultBranchesRequest = h2iApi.get('/api/filiais', { params: { grupoH2i: 's' } });

    Promise.all([financialBranchesRequest, defaultBranchesRequest, accountingBranchesRequest])
      .then(([financialBranchesReponse, defaultBranchesReponse, accountingBranchesReponse]) => {
        if (financialBranchesReponse.data.MESSAGE) {
          return;
        }
        setAccountingBranches(accountingBranchesReponse.data.filiais);
        setFinancialBranches(financialBranchesReponse.data.RESULT[0].filiais);
        setDefaultBranches(defaultBranchesReponse.data.RESULT[0].filiais);
        dispatch(setBranches(defaultBranchesReponse.data.RESULT[0].filiais));
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [dispatch, h2iApi, financialApi]);

  const contextValue: AppContextData = {
    isMobile,
    windowWidth,
    isOpenedMenu,
    handleOpenMenu,
    handleLogout,
    h2iApi,
    financialApi,
  };

  function handleOpenMenu() {
    setIsOpenedMenu(!isOpenedMenu);
  }

  function handleLogout() {
    logout();
  }

  return (
    <BrowserRouter history={history}>
      <AppContext.Provider value={contextValue}>
        <ThemeProvider theme={theme}>
          {loading ? (
            <SplashScreen />
          ) : (
            <BranchesProvider
              accountingBranches={accountingBranches}
              defaultBranches={defaultBranches}
              financialBranches={financialBranches}
            >
              <MessagingProvider>
                <PermissionRulesProvider>
                  <ProductSearchProvider>
                    <ErrorHandlerProvider>
                      <Routes />
                    </ErrorHandlerProvider>
                  </ProductSearchProvider>
                </PermissionRulesProvider>
              </MessagingProvider>
            </BranchesProvider>
          )}
        </ThemeProvider>
      </AppContext.Provider>
    </BrowserRouter>
  );
};

export default App;
