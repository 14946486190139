import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { moneyFormat, percentFormat } from 'helpers/numberFormat';
import { useApp } from 'hooks/app';
import { useCallback, useEffect, useState } from 'react';
import { BranchFinancialTicket, CustomerFinancialTicket, FinancialTicket } from 'types/financialReportList';
import { FinancialTicketFilterParams } from '../FinancialTicketReport';
import { parsePtBRDate } from 'helpers/parsePtBRDate';
import { BillSetting } from 'types/billSetting';
import { api } from 'services/api';

export interface FinancialTicketTotal {
  open: number;
  expired: number;
  toReceive: number;
  percentageExpired: number;
  formattedToReceive?: string;
  formattedExpired?: string;
  formattedOpen?: string;
  formattedPercentageExpired?: string;
}

export function useFetchFinancialTicketReport(filter: FinancialTicketFilterParams) {
  const [tickets, setTickets] = useState<FinancialTicket[]>([]);
  const [ticketsBillingNotice, setTicketsBillingNotice] = useState<FinancialTicket[]>([]);
  const [ticketsDueDateReminders, setTicketsDueDateReminders] = useState<FinancialTicket[]>([]);
  const [total, setTotal] = useState<FinancialTicketTotal>({ open: 0, expired: 0, toReceive: 0, percentageExpired: 0 });
  const [customerTickets, setCustomerTickets] = useState<FinancialTicket[]>([]);
  const [agroupedBranchesTicket, setAgroupedBranchesTicket] = useState<BranchFinancialTicket[]>([]);
  const [agroupedCustomersTicket, setAgroupedCustomersTicket] = useState<CustomerFinancialTicket[]>([]);
  const [agroupedCustomersTicketExpired, setAgroupedCustomersTicketExpired] = useState<CustomerFinancialTicket[]>([]);
  const { h2iApi, isOpenedMenu, handleOpenMenu } = useApp();
  const [loading, setLoading] = useState(false);
  const [loadingWarnings, setLoadingWarnings] = useState(false);
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [loadingCustomerInfo, setLoadingCustomerInfo] = useState(false);
  const [settings, setSettings] = useState<BillSetting[]>([]);

  useEffect(() => {
    api
      .get<BillSetting[]>('/bank-billet-settings')
      .then(response => setSettings(response.data))
      .catch(err => console.error(err));
  }, []);

  function handleClickCnpjField(document: string) {
    if (!h2iApi) return;

    setLoadingCustomerInfo(true);

    h2iApi
      .get(`/api/relboletos`, {
        params: {
          id_filial: filter.branchId || '',
          tipo_pesquisa: filter.typeDate,
          situacao: filter.situation,
          local_baixa: filter.dropoffLocation === 'T' ? '' : filter.dropoffLocation,
          filtro: '',
          cnpj: document,
        },
      })
      .then(response => {
        const _response: FinancialTicket[] = response.data.relboleto;

        if (!_response) {
          setCustomerTickets([]);
          return;
        }

        setCustomerTickets(
          _response
            .filter(item => item.cnpj_cpf === document)
            .map(item => {
              const dueDate = item.vencimento && parsePtBRDate(item.vencimento).toISOString();
              const releaseDate = item.data_lanc && parsePtBRDate(item.data_lanc).toISOString();
              const payDate = item.data_pag && parsePtBRDate(item.data_pag).toISOString();
              const beforeDate = item.data_pre && parsePtBRDate(item.data_pre).toISOString();

              item.formattedTotal = moneyFormat(item.valor_total);
              item.formattedValue = moneyFormat(item.valor);
              item.formattedTrafficTicket = moneyFormat(item.multa);
              item.formattedFees = moneyFormat(item.juros);
              item.formattedDueDate = dueDate;
              item.formattedReleaseDate = releaseDate;
              item.formattedPayDate = payDate;
              item.formattedBeforeDate = beforeDate;

              return item;
            }),
        );
      })
      .catch(err => console.error(err))
      .finally(() => setLoadingCustomerInfo(false));
  }

  const handleSearch = useCallback(async () => {
    if (!h2iApi) return;
    if (isOpenedMenu) handleOpenMenu();

    setLoading(true);
    setLoadingCustomers(true);

    const formattedInitialDate = !filter.initialDate ? '' : format(filter.initialDate, 'P', { locale: ptBR });
    const formattedFinalDate = !filter.finalDate ? '' : format(filter.finalDate, 'P', { locale: ptBR });

    const params = {
      data_ini: formattedInitialDate,
      data_fim: formattedFinalDate,
      id_banco: filter.bank_billet_setting_id || '',
      id_filial: filter.branchId || '',
      local_baixa: filter.dropoffLocation === 'T' ? '' : filter.dropoffLocation,
      filtro: filter.search,
      tipo_pesquisa: filter.typeDate,
      situacao: filter.situation,
    };

    const fetchTickets = h2iApi.get(`/api/relboletos`, {
      params,
    });

    const fetchBranchesTickets = h2iApi.get(`/api/getboletosfilial`, {
      params,
    });

    const fetchCustomersTickets = h2iApi.get(`/api/getboletoscliente`, {
      params,
    });

    const fetchDashboard = h2iApi.get('/api/dashboardboletos');

    const fetchCustomersTicketsExpired = h2iApi.get(`/api/getboletoscliente`, {
      params: {
        ...params,
        situacao: 'I',
      },
    });

    Promise.all([fetchCustomersTickets, fetchCustomersTicketsExpired])
      .then(([responseCustomersTickets, responseCustomersTicketsExpired]) => {
        const _responseCustomers: CustomerFinancialTicket[] = responseCustomersTickets.data.relboleto;
        const _responseCustomersExpired: CustomerFinancialTicket[] = responseCustomersTicketsExpired.data.relboleto;

        if (!_responseCustomers) {
          setAgroupedCustomersTicket([]);
        } else {
          setAgroupedCustomersTicket(_responseCustomers.filter(item => !!item.cnpj_cpf));
        }

        if (!_responseCustomersExpired) {
          setAgroupedCustomersTicketExpired([]);
        } else {
          setAgroupedCustomersTicketExpired(
            _responseCustomersExpired.filter(item => !!item.cnpj_cpf).filter(item => item.dias_atraso >= filter.days),
          );
        }
      })
      .finally(() => setLoadingCustomers(false));

    Promise.all([fetchTickets, fetchBranchesTickets, fetchDashboard])
      .then(([responseTickets, responseBranchesTickets, responseDashboard]) => {
        const _response: FinancialTicket[] = responseTickets.data.relboleto;
        const _responseBranches: BranchFinancialTicket[] = responseBranchesTickets.data.relboletofilial;

        if (!_response) {
          setAgroupedCustomersTicketExpired([]);
          setAgroupedCustomersTicket([]);
          setAgroupedBranchesTicket([]);
          setTicketsBillingNotice([]);
          setTicketsDueDateReminders([]);
          setTickets([]);
          return;
        }

        if (responseDashboard.data.relboleto) {
          setTotal({
            open: responseDashboard.data.relboleto[0].em_aberto,
            expired: responseDashboard.data.relboleto[0].vencidos,
            toReceive: responseDashboard.data.relboleto[0].a_receber,
            percentageExpired: responseDashboard.data.relboleto[0].percentual_vencidos,
            formattedToReceive: moneyFormat(responseDashboard.data.relboleto[0].a_receber),
            formattedExpired: moneyFormat(responseDashboard.data.relboleto[0].vencidos),
            formattedOpen: moneyFormat(responseDashboard.data.relboleto[0].em_aberto),
            formattedPercentageExpired: percentFormat(responseDashboard.data.relboleto[0].perc_inadimplencia),
          });
        }

        setTickets(
          _response.map(item => {
            const dueDate = item.vencimento && parsePtBRDate(item.vencimento).toISOString();
            const releaseDate = item.data_lanc && parsePtBRDate(item.data_lanc).toISOString();
            const payDate = item.data_pag && parsePtBRDate(item.data_pag).toISOString();

            item.formattedTotal = moneyFormat(item.valor_total);
            item.formattedValue = moneyFormat(item.valor);
            item.formattedTrafficTicket = moneyFormat(item.multa);
            item.formattedFees = moneyFormat(item.juros);
            item.formattedDueDate = dueDate;
            item.formattedReleaseDate = releaseDate;
            item.formattedPayDate = payDate;

            return item;
          }),
        );

        if (_responseBranches) {
          setAgroupedBranchesTicket(_responseBranches);
        }
      })
      .finally(() => setLoading(false));
  }, [h2iApi, filter, isOpenedMenu, handleOpenMenu]);

  const fetchWarnings = useCallback(() => {
    if (!h2iApi) return;

    setLoadingWarnings(true);

    const fetchBillingNotice = h2iApi.get('/api/getrelboletosavisocobranca');

    const fetchDueDateReminders = h2iApi.get('/api/getrelboletoslembrete');

    Promise.all([fetchBillingNotice, fetchDueDateReminders])
      .then(([responseBillingNotice, responseDueDateReminders]) => {
        const _responseBillingNotice: FinancialTicket[] = responseBillingNotice.data.relboleto;
        const _responseDueDateReminders: FinancialTicket[] = responseDueDateReminders.data.relboleto;

        if (!_responseBillingNotice) {
          setTicketsBillingNotice([]);
        } else {
          setTicketsBillingNotice(
            _responseBillingNotice.map(item => {
              const dueDate = item.vencimento && parsePtBRDate(item.vencimento).toISOString();
              const releaseDate = item.data_lanc && parsePtBRDate(item.data_lanc).toISOString();
              const payDate = item.data_pag && parsePtBRDate(item.data_pag).toISOString();
              const beforeDate = item.data_pre && parsePtBRDate(item.data_pre).toISOString();

              item.formattedTotal = moneyFormat(item.valor_total);
              item.formattedValue = moneyFormat(item.valor);
              item.formattedTrafficTicket = moneyFormat(item.multa);
              item.formattedFees = moneyFormat(item.juros);
              item.formattedDueDate = dueDate;
              item.formattedReleaseDate = releaseDate;
              item.formattedPayDate = payDate;
              item.formattedBeforeDate = beforeDate;
              return item;
            }),
          );
        }

        if (!_responseDueDateReminders) {
          setTicketsDueDateReminders([]);
        } else {
          setTicketsDueDateReminders(
            _responseDueDateReminders.map(item => {
              const dueDate = item.vencimento && parsePtBRDate(item.vencimento).toISOString();
              const releaseDate = item.data_lanc && parsePtBRDate(item.data_lanc).toISOString();
              const payDate = item.data_pag && parsePtBRDate(item.data_pag).toISOString();

              item.formattedTotal = moneyFormat(item.valor_total);
              item.formattedValue = moneyFormat(item.valor);
              item.formattedTrafficTicket = moneyFormat(item.multa);
              item.formattedFees = moneyFormat(item.juros);
              item.formattedDueDate = dueDate;
              item.formattedReleaseDate = releaseDate;
              item.formattedPayDate = payDate;

              return item;
            }),
          );
        }
      })
      .finally(() => setLoadingWarnings(false));
  }, [h2iApi]);

  function onCloseCustomerTickets() {
    setCustomerTickets([]);
  }

  return {
    handleSearch,
    fetchWarnings,
    loadingWarnings,
    settings,
    total,
    tickets,
    ticketsBillingNotice,
    ticketsDueDateReminders,
    loading,
    agroupedBranchesTicket,
    agroupedCustomersTicket,
    agroupedCustomersTicketExpired,
    loadingCustomerInfo,
    handleClickCnpjField,
    customerTickets,
    onCloseCustomerTickets,
    loadingCustomers,
  };
}
