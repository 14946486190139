import React from 'react';
import { List, styled } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import { AgroupedAbcProductPayment } from 'types/abcProduct';
import AgroupedPaymentItemModule from './AgroupedPaymentItemModule';

const CustomList = styled(List)({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr',
  gap: 5,
  flex: 1,
});

type AgroupedPaymentListModuleProps = {
  payments: AgroupedAbcProductPayment[];
};

const AgroupedPaymentListModule: React.FC<AgroupedPaymentListModuleProps> = ({ payments }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <CustomList>
      {payments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((payment, index) => (
        <AgroupedPaymentItemModule key={index + 1} payment={payment} />
      ))}
    </CustomList>
  );
};

export default AgroupedPaymentListModule;
