import React, { useEffect, useState } from 'react';
import { Button, Checkbox, FormControlLabel, Tooltip, Typography, styled } from '@mui/material';
import { moneyFormat } from 'helpers/numberFormat';
import useTableOrder from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { CustomerFinancialTicket } from 'types/financialReportList';
import { usePagination } from 'hooks/pagination';
import Pagination from 'components/pagination/Pagination';
import NoData from 'components/no-data/NoData';
import ConfirmDialog from 'components/confirm-dialog/ConfirmDialog';
import { useApp } from 'hooks/app';
import InsideLoading from 'components/loading/InsideLoading';
import { useMessaging } from 'hooks/messaging';
import { useFinancialTicket } from '../../hooks/useFinancialTicket';
import { usePermissionRules } from 'hooks/permissionRules';

const Container = styled('div')({
  gap: 10,
  borderRadius: 6,
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #eee',
  padding: 20,
  background: '#fff',
  overflow: 'auto',
  height: 650,
});

const Row = styled('li')(({ theme }) => ({
  '&.header': {
    position: 'sticky',
    top: -20,
    right: 0,
    left: 0,
    background: '#fff',
  },
  '&.select': {
    gridTemplateColumns: '40px 100px 110px 115px 105px 105px 90px 90px',
  },
  display: 'grid',
  columnGap: 10,
  borderBottom: '1px solid #eee',
  paddingBottom: 6,
  gridTemplateColumns: '100px 110px 115px 105px 105px 90px',
  '& > span': {
    gap: 5,
    fontSize: 11,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  '& > .numericData': {
    justifyContent: 'flex-end',
    textAlign: 'end',
  },
  '& > .quantityData': {
    justifyContent: 'center',
    textAlign: 'center',
  },
  '& .item-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: '1.5fr 110px 1fr 1fr 1fr 1fr',
    '&.select': {
      gridTemplateColumns: '40px 1.5fr 110px 1fr 1fr 1fr 1fr 1fr',
    },
  },
}));

const Title = styled('div')({});

const List = styled('ul')({
  display: 'grid',
  rowGap: 10,
});

const HeaderItem = styled('span')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#fff',
});

const Flex = styled('div')({
  background: '#fff',
  display: 'flex',
  flex: 1,
  width: '100%',
  position: 'sticky',
  alignItems: 'baseline',
  bottom: -20,
  left: 0,
  right: 0,
});

interface TopCustomersTicketProps {
  data: CustomerFinancialTicket[];
  handleClickCnpjField(cnpj: string): void;
  title?: string;
  showSelect?: boolean;
  showDocument?: boolean;
}

const TopCustomersTicket: React.FC<TopCustomersTicketProps> = ({
  data,
  title,
  handleClickCnpjField,
  showSelect = true,
  showDocument = true,
}) => {
  const [orderedIndex, sort] = useTableOrder();
  const { checkPermission } = usePermissionRules();
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [filtered, setFiltered] = useState<CustomerFinancialTicket[]>([]);
  const { rowsPerPage, page } = usePagination();
  const { handleSearch } = useFinancialTicket();
  const { h2iApi } = useApp();
  const { handleOpen } = useMessaging();
  const [selectAll, setSelectAll] = useState(false);
  const [saving, setSaving] = useState(false);
  const [selectedTickets, setSelectedTickets] = useState<CustomerFinancialTicket[]>([]);

  function handleToggle(ticket: CustomerFinancialTicket) {
    if (selectedTickets.some(_selectedTicket => _selectedTicket.cnpj_cpf === ticket.cnpj_cpf)) {
      setSelectAll(false);
      setSelectedTickets(selectedTickets.filter(_selectedTicket => _selectedTicket.cnpj_cpf !== ticket.cnpj_cpf));
    } else {
      const _tickets = [...selectedTickets, ticket];
      setSelectedTickets(_tickets);

      if (filtered.length === _tickets.length) {
        setSelectAll(true);
      }
    }
  }

  function handleSelectAll() {
    if (selectAll) {
      setSelectedTickets([]);
    } else {
      setSelectedTickets(filtered);
    }
    setSelectAll(!selectAll);
  }

  const isChecked = (ticket: CustomerFinancialTicket) => {
    return selectedTickets.some(selectedTicket => selectedTicket.cnpj_cpf === ticket.cnpj_cpf);
  };

  useEffect(() => {
    setFiltered(data);
  }, [data]);

  function handleSort(index: string) {
    const f = sort(index, filtered);
    setFiltered(f);
  }

  function onSave() {
    if (!h2iApi) return;
    if (!checkPermission('financial.reports.ticket.button-reset-credit-limit')) {
      handleOpen('Sem permissão');
      return;
    }

    setSaving(true);

    const cnpjs = selectedTickets.map(ticket => ticket.cnpj_cpf);
    h2iApi
      .post('/api/zerarlimitecredito', cnpjs)
      .then(() => {
        handleOpen('Limite de crédito zerado com sucesso');
        setConfirmDialog(false);
        handleSearch();
        setSelectedTickets([]);
      })
      .catch(() => {
        handleOpen('Erro ao zerar limite de crédito');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  return (
    <Container>
      {saving && <InsideLoading />}
      {confirmDialog && (
        <ConfirmDialog
          handleNoClick={() => setConfirmDialog(false)}
          handleYesClick={onSave}
          title="Deseja zerar o limite de crédito?"
        />
      )}

      {title && (
        <Title>
          <Typography fontSize={13} variant="subtitle2" align="center">
            {title}
          </Typography>
        </Title>
      )}
      {data.length > 0 ? (
        <List>
          <Row className={showSelect ? 'header select' : 'header'}>
            {showSelect && (
              <HeaderItem>
                <FormControlLabel
                  style={{ justifyContent: 'center', marginRight: 0 }}
                  label=""
                  control={
                    <Checkbox checked={selectAll} onChange={handleSelectAll} size="small" style={{ padding: 0 }} />
                  }
                />
              </HeaderItem>
            )}
            <span>
              <Typography fontSize={13} onClick={() => handleSort('nome')} variant="subtitle2">
                Nome
              </Typography>
              {orderedIndex.index === 'nome' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            {showDocument && (
              <span>
                <Typography fontSize={13} onClick={() => handleSort('cnpj_cpf')} variant="subtitle2">
                  CNPJ
                </Typography>
                {orderedIndex.index === 'cnpj_cpf' && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </span>
            )}

            <span className="quantityData">
              <Typography fontSize={13} onClick={() => handleSort('dias_atraso')} variant="subtitle2">
                Dias atraso
              </Typography>
              {orderedIndex.index === 'dias_atraso' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span className="numericData">
              <Typography fontSize={13} onClick={() => handleSort('limite_credito')} align="right" variant="subtitle2">
                Limite crédito
              </Typography>
              {orderedIndex.index === 'limite_credito' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span className="numericData">
              <Typography fontSize={13} onClick={() => handleSort('em_aberto')} align="right" variant="subtitle2">
                Em aberto
              </Typography>
              {orderedIndex.index === 'em_aberto' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span className="numericData">
              <Typography fontSize={13} onClick={() => handleSort('vencidos')} variant="subtitle2" align="right">
                Em atraso
              </Typography>
              {orderedIndex.index === 'vencidos' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span className="numericData">
              <Typography fontSize={13} onClick={() => handleSort('a_receber')} variant="subtitle2" align="right">
                A vencer
              </Typography>
              {orderedIndex.index === 'a_receber' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>
          </Row>
          {filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
            <Row className={showSelect ? 'select' : ''} key={index}>
              {showSelect && (
                <FormControlLabel
                  style={{ justifyContent: 'center', marginRight: 0 }}
                  label=""
                  control={
                    <Checkbox
                      size="small"
                      style={{ padding: 0 }}
                      checked={isChecked(item)}
                      onChange={() => handleToggle(item)}
                    />
                  }
                />
              )}
              <Tooltip title="Clique para abrir a relação de boletos">
                <Typography
                  onClick={() => handleClickCnpjField(item.cnpj_cpf)}
                  color="primary"
                  fontWeight={500}
                  className="item-name"
                  align="right"
                  variant="caption"
                >
                  {item.nome}
                </Typography>
              </Tooltip>

              {showDocument && <Typography variant="caption">{item.cnpj_cpf}</Typography>}

              <Typography className="quantityData" align="center" variant="caption">
                {item.dias_atraso}
              </Typography>
              <Typography className="numericData" align="right" variant="caption">
                {moneyFormat(item.limite_credito)}
              </Typography>
              <Typography className="numericData" align="right" variant="caption">
                {moneyFormat(item.em_aberto)}
              </Typography>
              <Typography className="numericData" color="error" align="right" variant="caption">
                {moneyFormat(item.vencidos)}
              </Typography>
              <Typography className="numericData" align="right" variant="caption">
                {moneyFormat(item.a_receber)}
              </Typography>
            </Row>
          ))}
        </List>
      ) : (
        <NoData message="Conteúdo indisponível" />
      )}
      <Flex>
        <div style={{ flex: 1 }}>
          {selectedTickets.length > 0 && (
            <Button
              disabled={!checkPermission('financial.charge.billing-management.button-reset-credit-limit') || saving}
              onClick={() => setConfirmDialog(true)}
              variant="contained"
            >
              Zerar limite de crédito
            </Button>
          )}
        </div>
        <Pagination count={data.length} />
      </Flex>
    </Container>
  );
};

export default TopCustomersTicket;
