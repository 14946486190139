import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { moneyFormat } from 'helpers/numberFormat';
import { useApp } from 'hooks/app';
import { useCallback, useEffect, useState } from 'react';
import { FinancialTicket } from 'types/financialReportList';
import { parsePtBRDate } from 'helpers/parsePtBRDate';
import { BillSetting } from 'types/billSetting';
import { api } from 'services/api';

export interface FinancialTicketFilterParams {
  initialDate: Date | null;
  finalDate: Date | null;
  branchId: number;
  typeDate: string;
  search: string;
  situation: string;
  dropoffLocation: string;
  bank_billet_setting_id: number | null;
}

export function useFetchFinancialTicketReport(filter: FinancialTicketFilterParams) {
  const [tickets, setTickets] = useState<FinancialTicket[]>([]);
  const { h2iApi } = useApp();
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState<BillSetting[]>([]);

  useEffect(() => {
    api
      .get<BillSetting[]>('/bank-billet-settings')
      .then(response => setSettings(response.data))
      .catch(err => console.error(err));
  }, []);

  const handleSearch = useCallback(async () => {
    if (!h2iApi) return;

    setLoading(true);

    const formattedInitialDate = !filter.initialDate ? '' : format(filter.initialDate, 'P', { locale: ptBR });
    const formattedFinalDate = !filter.finalDate ? '' : format(filter.finalDate, 'P', { locale: ptBR });

    const params = {
      data_ini: formattedInitialDate,
      data_fim: formattedFinalDate,
      id_banco: filter.bank_billet_setting_id || '',
      id_filial: filter.branchId || '',
      filtro: filter.search,
      tipo_pesquisa: filter.typeDate,
      situacao: filter.situation,
      local_baixa: filter.dropoffLocation === 'T' ? '' : filter.dropoffLocation,
    };

    const fetchTickets = h2iApi.get(`/api/relboletos`, {
      params,
    });

    Promise.all([fetchTickets])
      .then(([responseTickets]) => {
        const _response: FinancialTicket[] = responseTickets.data.relboleto;

        if (!_response) {
          setTickets([]);
          return;
        }

        setTickets(
          _response.map(item => {
            const dueDate = item.vencimento && parsePtBRDate(item.vencimento).toISOString();
            const releaseDate = item.data_lanc && parsePtBRDate(item.data_lanc).toISOString();
            const payDate = item.data_pag && parsePtBRDate(item.data_pag).toISOString();
            const beforeDate = item.data_pre && parsePtBRDate(item.data_pre).toISOString();

            item.formattedTotal = moneyFormat(item.valor_total);
            item.formattedValue = moneyFormat(item.valor);
            item.formattedTrafficTicket = moneyFormat(item.multa);
            item.formattedFees = moneyFormat(item.juros);
            item.formattedDueDate = dueDate;
            item.formattedReleaseDate = releaseDate;
            item.formattedPayDate = payDate;
            item.formattedBeforeDate = beforeDate;

            return item;
          }),
        );
      })
      .finally(() => setLoading(false));
  }, [h2iApi, filter]);

  return {
    handleSearch,
    settings,
    tickets,
    loading,
  };
}
