import React from 'react';
import { styled } from '@mui/material';
import { LineAxis } from '@mui/icons-material';
import InsideLoading from 'components/loading/InsideLoading';
import { FinancialTicketTotal } from './hooks/useFetchFinancialTicketReport';

interface BlockProps {
  backgroundColor?: string;
}

const Col1 = styled('div')(({ theme }) => ({
  gap: 5,
  flex: 1,
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr',
  },
}));

const Row = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  display: 'flex',
  padding: 10,
  gap: 5,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    rowGap: 15,
  },
}));

const Block = styled('div')<BlockProps>(props => ({
  border: '1px solid #eee',
  backgroundColor: '#fff',
  flexDirection: 'column',
  display: 'flex',
  flex: 1,
  '& .title': {
    background: props.backgroundColor ?? 'transparent',
    fontSize: 12,
    borderBottom: '1px solid #f5f5f5',
    padding: '8px 20px 8px 20px',
  },
  '& .main': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 20,
  },
  '& .value': {
    fontWeight: 600,
    fontSize: 16,
    [props.theme.breakpoints.down('lg')]: {
      fontSize: 12,
    },
  },
  '& .defeated': {
    color: props.theme.palette.error.main,
  },
}));

interface SalesTicketReportTotalProps {
  loading: boolean;
  label?: string;
  total: FinancialTicketTotal;
}

const SalesTicketReportTotal: React.FC<SalesTicketReportTotalProps> = ({ label, loading, total }) => {
  return (
    <Row>
      <Col1>
        <Block className="first-child">
          <span className="title">Total de boletos em aberto</span>
          <div className="main">
            {loading ? <InsideLoading /> : <span className="value">{total.formattedOpen}</span>}
            <LineAxis color="secondary" />
          </div>
        </Block>

        <Block>
          <span className="title">Total vencidos (em atraso)</span>
          <div className="main">
            {loading ? <InsideLoading /> : <span className="value defeated">{total.formattedExpired}</span>}
            <LineAxis color="secondary" />
          </div>
        </Block>

        <Block>
          <span className="title">% {label}</span>
          <div className="main">
            {loading ? <InsideLoading /> : <span className="value">{total.formattedPercentageExpired}</span>}
            <LineAxis color="secondary" />
          </div>
        </Block>

        <Block>
          <span className="title">Total a vencer</span>
          <div className="main">
            {loading ? <InsideLoading /> : <span className="value">{total.formattedToReceive}</span>}
            <LineAxis color="secondary" />
          </div>
        </Block>
      </Col1>
    </Row>
  );
};

export default SalesTicketReportTotal;
