import { Button, styled, Typography } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import React, { FC } from 'react';
import history from 'services/history';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: 20,
});

const LinksContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: 20,
  rowGap: 15,
  maxWidth: 330,
  justifyContent: 'start',
  '& > button': {
    textTransform: 'none',
  },
});

const Pipedrive: FC = () => {
  function handleRedirect(path: string) {
    history.push(path);
  }

  return (
    <>
      <Appbar title="Pipedrive" />
      <Container>
        <Typography variant="h6">Selectione o tipo de lead</Typography>

        <LinksContainer>
          <Button variant="outlined" onClick={() => handleRedirect('/pipedrive-ro')}>
            RO - Registro de Oportunidade
          </Button>
          <Button variant="outlined" onClick={() => handleRedirect('/pipedrive-pro')}>
            PRO - Projeto
          </Button>
          <Button variant="outlined" onClick={() => handleRedirect('/pipedrive-va')}>
            VA - Venda Agenciada
          </Button>
        </LinksContainer>
      </Container>
    </>
  );
};

export default Pipedrive;
