import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { IconButton, Tooltip, styled } from '@mui/material';
import Pagination from 'components/pagination/Pagination';
import Appbar from 'components/appbar/Appbar';
import { useApp } from 'hooks/app';
import useTableOrder from 'hooks/tableOrder';
import TableContainer from 'components/table/TableContainer';
import PaginationProvider from 'hooks/pagination';
import { endOfDay, startOfMonth } from 'date-fns';
import FinancialTicketData from './FinancialTicketData';
import { FinancialTicketProvider } from './hooks/useFinancialTicket';
import FinancialTicketFilter from './FinancialTicketFilter';
import { FinancialTicket } from 'types/financialReportList';
import { ticketTableTemplate } from './ticketTableTemplate';
import TicketListTable from './list/table/TicketListTable';
import TicketListModule from './list/module/TicketListModule';
import FinancialTicketMenu from './FinancialTicketMenu';
import FinancialTicketFilterBox from './FinancialTicketFilterBox';
import { useFetchFinancialTicketReport } from './hooks/useFetchFinancialTicketReport';
import SalesTicketReportTotal from './SalesTicketReportTotal';
import FilterActions from 'components/filter-box/FilterActions';
import { useSelector } from 'store/selector';
import { usePermissionRules } from 'hooks/permissionRules';
import FinancialTicketExport from './export/FinancialTicketExport';
import { useFinancial } from 'pages/financial/hooks/useFinancial';
import { Print } from '@mui/icons-material';
import FinancialTicketDashboard from './tabs/dashboard/FinancialTicketDashboard';
import FinancialTicketTabs from './FinancialTicketTabs';
import { useDisplayMode } from 'hooks/useDisplayMode';
import { FaFileExcel } from 'react-icons/fa';
import NoData from 'components/no-data/NoData';
import TableLoading from 'components/loading/TableLoading';
import Loading from 'components/loading/Loading';
import FinancialCustomerTicketsDialog from './dialog/FinancialCustomerTicketsDialog';
import CustomersTicketExpired from './expired/CustomersTicketExpired';
import TopCustomersTicket from './tabs/dashboard/TopCustomersTicket';
import { api } from 'services/api';
import FailedSendTicketsDialog from './error/FailedSendTicketsDialog';
import TicketActions from './actions/TicketActions';
import SalesTicketReportListTotal from './SalesTicketReportListTotal';
import { useMessaging } from 'hooks/messaging';
import PageHeader from 'components/page-header/PageHeader';

export interface FinancialTicketFilterParams {
  initialDate: Date | null;
  finalDate: Date | null;
  days: number;
  branchId: number;
  typeDate: string;
  dropoffLocation: string;
  search: string;
  situation: string;
  bank_billet_setting_id: number | null;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const HeaderActions = styled('div')({
  display: 'flex',
  columnGap: '10px',
});

const Flex = styled('div')({
  display: 'flex',
  flex: 1,
  width: '100%',
});

const FinancialTicketReport: React.FC = () => {
  const { h2iApi } = useApp();
  const { handleBack } = useFinancial();
  const [tab, setTab] = useState('dashboard');
  const { windowWidth, isMobile } = useApp();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [displayMode] = useDisplayMode();
  const { handleOpen } = useMessaging();
  const [dialogDate, setDialogDate] = useState(false);
  const [shownReport, setShownReport] = useState(false);
  const [orderedIndex, sort] = useTableOrder();
  const [filteredTickets, setFilteredTickets] = useState<FinancialTicket[]>([]);
  const [filteredTicketsBillingNotice, setFilteredTicketsBillingNotice] = useState<FinancialTicket[]>([]);
  const [filteredTicketsDueDateReminders, setFilteredTicketsDueDateReminders] = useState<FinancialTicket[]>([]);
  const [selectedTicket, setSelectedTicket] = useState<FinancialTicket | null>(null);
  const [excelExport, setExcelExport] = useState(false);
  const user = useSelector(state => state.user);
  const [saving, setSaving] = useState(false);
  const branches = useSelector(state => state.branches);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllDueDateReminders, setSelectAllDueDateReminders] = useState(false);
  const [selectAllBillingNotice, setSelectAllBillingNotice] = useState(false);
  const [selectedTickets, setSelectedTickets] = useState<FinancialTicket[]>([]);
  const [selectedTicketsBillingNotice, setSelectedTicketsBillingNotice] = useState<FinancialTicket[]>([]);
  const [selectedTicketsDueDateReminders, setSelectedTicketsDueDateReminders] = useState<FinancialTicket[]>([]);
  const [filter, setFilter] = useState<FinancialTicketFilterParams>({
    initialDate: startOfMonth(new Date()),
    days: 3,
    finalDate: endOfDay(new Date()),
    branchId:
      user && user.branchList?.length !== 0
        ? parseInt(user.branchList.length === branches.length ? '0' : user.branchList[0])
        : 999,
    situation: 'N',
    typeDate: 'V',
    bank_billet_setting_id: null,
    search: '',
    dropoffLocation: 'T',
  });
  const {
    handleSearch,
    fetchWarnings,
    loadingWarnings,
    total,
    tickets,
    ticketsBillingNotice,
    ticketsDueDateReminders,
    loading,
    agroupedBranchesTicket,
    agroupedCustomersTicket,
    agroupedCustomersTicketExpired,
    loadingCustomerInfo,
    handleClickCnpjField,
    customerTickets,
    settings,
    onCloseCustomerTickets,
    loadingCustomers,
  } = useFetchFinancialTicketReport(filter);
  const [failedTickets, setFailedTickets] = useState<FinancialTicket[]>([]);
  const { checkPermission } = usePermissionRules();

  const handleToggle = (ticket: FinancialTicket) => {
    if (selectedTickets.some(_selectedTicket => _selectedTicket.id === ticket.id)) {
      setSelectedTickets(selectedTickets.filter(_selectedTicket => _selectedTicket.id !== ticket.id));
    } else {
      setSelectedTickets([...selectedTickets, ticket]);
    }
  };

  const handleToggleDueDateReminders = (ticket: FinancialTicket) => {
    if (selectedTicketsDueDateReminders.some(_selectedTicket => _selectedTicket.id === ticket.id)) {
      setSelectedTicketsDueDateReminders(
        selectedTicketsDueDateReminders.filter(_selectedTicket => _selectedTicket.id !== ticket.id),
      );
    } else {
      setSelectedTicketsDueDateReminders([...selectedTicketsDueDateReminders, ticket]);
    }
  };

  const handleToggleBillingNotice = (ticket: FinancialTicket) => {
    if (selectedTicketsBillingNotice.some(_selectedTicket => _selectedTicket.id === ticket.id)) {
      setSelectedTicketsBillingNotice(
        selectedTicketsBillingNotice.filter(_selectedTicket => _selectedTicket.id !== ticket.id),
      );
    } else {
      setSelectedTicketsBillingNotice([...selectedTicketsBillingNotice, ticket]);
    }
  };

  const selectedBranch = useMemo(() => {
    const branch = branches.find(branch => branch.id === filter.branchId);

    if (!branch) {
      return null;
    }

    return branch;
  }, [filter, branches]);

  useEffect(() => {
    setFilteredTickets(tickets);
  }, [tickets]);

  useEffect(() => {
    setFilteredTicketsBillingNotice(ticketsBillingNotice);
  }, [ticketsBillingNotice]);

  useEffect(() => {
    setFilteredTicketsDueDateReminders(ticketsDueDateReminders);
  }, [ticketsDueDateReminders]);

  function handleChangeFilter(index: keyof FinancialTicketFilterParams, value: any) {
    if (index === 'days' && value === 0) {
      setFilter(state => ({
        ...state,
        days: 1,
      }));
      return;
    }

    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleSort(index: string) {
    const filtered = sort(index, filteredTickets);
    setFilteredTickets(filtered);
  }

  function handleSortBillingNotice(index: string) {
    const filtered = sort(index, filteredTicketsBillingNotice);
    setFilteredTicketsBillingNotice(filtered);
  }

  function handleSortDueDateReminders(index: string) {
    const filtered = sort(index, filteredTicketsDueDateReminders);
    setFilteredTicketsDueDateReminders(filtered);
  }

  function handleSearchSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    handleSearch();
    fetchWarnings();
  }

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedTickets([]);
    } else {
      setSelectedTickets(filteredTickets);
    }
    setSelectAll(!selectAll);
  };

  const handleSelectAllDueDateReminders = () => {
    if (selectAllDueDateReminders) {
      setSelectedTicketsDueDateReminders([]);
    } else {
      setSelectedTicketsDueDateReminders(filteredTicketsDueDateReminders);
    }
    setSelectAllDueDateReminders(!selectAllDueDateReminders);
  };

  const handleSelectAllBillingNotice = () => {
    if (selectAllBillingNotice) {
      setSelectedTicketsBillingNotice([]);
    } else {
      setSelectedTicketsBillingNotice(filteredTicketsBillingNotice);
    }
    setSelectAllBillingNotice(!selectAllBillingNotice);
  };

  const agroupedCustomerWithCreditLimitTicket = useMemo(() => {
    return agroupedCustomersTicketExpired.filter(item => item.limite_credito > 0).filter(item => item.vencidos > 0);
  }, [agroupedCustomersTicketExpired]);

  async function sendReminder() {
    if (selectedTickets.length === 0) {
      return;
    }

    setSaving(true);
    const results = await Promise.allSettled(
      selectedTickets.map(async ticket => {
        if (!ticket.url) {
          throw new Error(`Boleto #${ticket.id} não disponível`);
        }

        const response = await api.post('/email-templates/14/__send', {
          ticket_id: ticket.id,
          due_date: ticket.vencimento,
          ticket_link: ticket.url,
          emails: [ticket.email],
          base_template_id: 15,
          street: '.',
          neighborhood: '.',
          city: '.',
          state: 'Bahia',
          postal_code: '.',
          phone: '.',
          company_name: 'Eltecno Distribuição de Informática LTDA',
        });

        if (!response.data.ok) {
          throw new Error(`Erro ao enviar boleto #${ticket.id}`);
        }
      }),
    ).finally(() => setSaving(false));

    const failed = selectedTickets.filter((_, index) => results[index].status === 'rejected').filter(item => !item.url);
    const success = selectedTickets
      .filter(item => item.url)
      .map(item => ({
        id: item.id,
        id_filial: item.id_filial,
      }));

    if (h2iApi) {
      h2iApi.post('/api/savelembreteemailfinanceiro', { email: success });
    }
    setFailedTickets(failed);

    handleOpen(
      `Foram enviados ${selectedTickets.length - failed.length}/${selectedTickets.length} lembretes de vencimento`,
    );
  }

  async function sendAlert() {
    if (selectedTickets.length === 0) {
      return;
    }
    setSaving(true);

    const results = await Promise.allSettled(
      selectedTickets.map(async ticket => {
        if (!ticket.url) {
          throw new Error(`Boleto #${ticket.id} não disponível`);
        }

        const response = await api.post('/email-templates/16/__send', {
          ticket_id: ticket.id,
          due_date: ticket.vencimento,
          ticket_link: ticket.url,
          emails: [ticket.email],
          base_template_id: 15,
          street: '.',
          neighborhood: '.',
          city: '.',
          state: 'Bahia',
          postal_code: '.',
          phone: '.',
          company_name: 'Eltecno Distribuição de Informática LTDA',
        });

        if (!response.data.ok) {
          throw new Error(`Erro ao enviar boleto #${ticket.id}`);
        }
      }),
    ).finally(() => setSaving(false));

    const failed = selectedTickets.filter((_, index) => results[index].status === 'rejected').filter(item => !item.url);
    const success = selectedTickets
      .filter(item => item.url)
      .map(item => ({
        id: item.id,
        id_filial: item.id_filial,
      }));

    if (h2iApi) {
      h2iApi.post('/api/saveavisoemailfinanceiro', { email: success });
    }
    setFailedTickets(failed);

    handleOpen(`Foram enviados ${selectedTickets.length - failed.length}/${selectedTickets.length} avisos de débito`);
  }

  async function sendAlertDueDateReminders() {
    if (selectedTicketsDueDateReminders.length === 0) {
      return;
    }
    setSaving(true);

    const results = await Promise.allSettled(
      selectedTicketsDueDateReminders.map(async ticket => {
        if (!ticket.url) {
          throw new Error(`Boleto #${ticket.id} não disponível`);
        }

        const response = await api.post('/email-templates/16/__send', {
          ticket_id: ticket.id,
          due_date: ticket.vencimento,
          ticket_link: ticket.url,
          emails: [ticket.email],
          base_template_id: 15,
          street: '.',
          neighborhood: '.',
          city: '.',
          state: 'Bahia',
          postal_code: '.',
          phone: '.',
          company_name: 'Eltecno Distribuição de Informática LTDA',
        });

        if (!response.data.ok) {
          throw new Error(`Erro ao enviar boleto #${ticket.id}`);
        }
      }),
    ).finally(() => setSaving(false));

    const failed = selectedTicketsDueDateReminders
      .filter((_, index) => results[index].status === 'rejected')
      .filter(item => !item.url);
    const success = selectedTicketsDueDateReminders
      .filter(item => item.url)
      .map(item => ({
        id: item.id,
        id_filial: item.id_filial,
      }));

    if (h2iApi) {
      h2iApi.post('/api/saveavisoemailfinanceiro', { email: success });
    }
    setFailedTickets(failed);

    handleOpen(
      `Foram enviados ${selectedTicketsDueDateReminders.length - failed.length}/${
        selectedTicketsDueDateReminders.length
      } avisos de débito`,
    );
  }

  async function sendReminderDueDateReminders() {
    if (selectedTicketsDueDateReminders.length === 0) {
      return;
    }
    setSaving(true);

    const results = await Promise.allSettled(
      selectedTicketsDueDateReminders.map(async ticket => {
        if (!ticket.url) {
          throw new Error(`Boleto #${ticket.id} não disponível`);
        }

        const response = await api.post('/email-templates/14/__send', {
          ticket_id: ticket.id,
          due_date: ticket.vencimento,
          ticket_link: ticket.url,
          emails: [ticket.email],
          base_template_id: 15,
          street: '.',
          neighborhood: '.',
          city: '.',
          state: 'Bahia',
          postal_code: '.',
          phone: '.',
          company_name: 'Eltecno Distribuição de Informática LTDA',
        });

        if (!response.data.ok) {
          throw new Error(`Erro ao enviar boleto #${ticket.id}`);
        }
      }),
    ).finally(() => setSaving(false));

    const failed = selectedTicketsDueDateReminders
      .filter((_, index) => results[index].status === 'rejected')
      .filter(item => !item.url);
    const success = selectedTicketsDueDateReminders
      .filter(item => item.url)
      .map(item => ({
        id: item.id,
        id_filial: item.id_filial,
      }));

    if (h2iApi) {
      h2iApi.post('/api/savelembreteemailfinanceiro', { email: success });
    }
    setFailedTickets(failed);

    handleOpen(
      `Foram enviados ${selectedTicketsDueDateReminders.length - failed.length}/${
        selectedTicketsDueDateReminders.length
      } lembretes de vencimento`,
    );
  }

  async function sendReminderBillingNotice() {
    if (!h2iApi) return;
    if (selectedTicketsBillingNotice.length === 0) {
      return;
    }
    setSaving(true);

    const results = await Promise.allSettled(
      selectedTicketsBillingNotice.map(async ticket => {
        if (!ticket.url) {
          throw new Error(`Boleto #${ticket.id} não disponível`);
        }

        const response = await api.post('/email-templates/14/__send', {
          ticket_id: ticket.id,
          due_date: ticket.vencimento,
          ticket_link: ticket.url,
          emails: [ticket.email],
          base_template_id: 15,
          street: '.',
          neighborhood: '.',
          city: '.',
          state: 'Bahia',
          postal_code: '.',
          phone: '.',
          company_name: 'Eltecno Distribuição de Informática LTDA',
        });

        if (!response.data.ok) {
          throw new Error(`Erro ao enviar boleto #${ticket.id}`);
        }
      }),
    ).finally(() => setSaving(false));

    const failed = selectedTicketsBillingNotice
      .filter((_, index) => results[index].status === 'rejected')
      .filter(item => !item.url);
    const success = selectedTicketsBillingNotice
      .filter(item => item.url)
      .map(item => ({
        id: item.id,
        id_filial: item.id_filial,
      }));

    h2iApi.post('/api/savelembreteemailfinanceiro', { email: success }).finally(fetchWarnings);

    setFailedTickets(failed);

    handleOpen(
      `Foram enviados ${selectedTicketsBillingNotice.length - failed.length}/${
        selectedTicketsBillingNotice.length
      } lembretes de vencimento`,
    );
  }

  async function sendAlertBillingNotice() {
    if (!h2iApi) return;
    if (selectedTicketsBillingNotice.length === 0) {
      return;
    }
    setSaving(true);

    const results = await Promise.allSettled(
      selectedTicketsBillingNotice.map(async ticket => {
        if (!ticket.url) {
          throw new Error(`Boleto #${ticket.id} não disponível`);
        }

        const response = await api.post('/email-templates/16/__send', {
          ticket_id: ticket.id,
          due_date: ticket.vencimento,
          ticket_link: ticket.url,
          emails: [ticket.email],
          base_template_id: 15,
          street: '.',
          neighborhood: '.',
          city: '.',
          state: 'Bahia',
          postal_code: '.',
          phone: '.',
          company_name: 'Eltecno Distribuição de Informática LTDA',
        });

        if (!response.data.ok) {
          throw new Error(`Erro ao enviar boleto #${ticket.id}`);
        }
      }),
    ).finally(() => setSaving(false));

    const failed = selectedTicketsBillingNotice
      .filter((_, index) => results[index].status === 'rejected')
      .filter(item => !item.url);
    const success = selectedTicketsBillingNotice
      .filter(item => item.url)
      .map(item => ({
        id: item.id,
        id_filial: item.id_filial,
      }));

    h2iApi.post('/api/saveavisoemailfinanceiro', { email: success }).finally(fetchWarnings);

    setFailedTickets(failed);

    handleOpen(
      `Foram enviados ${selectedTicketsBillingNotice.length - failed.length}/${
        selectedTicketsBillingNotice.length
      } avisos de débito`,
    );
  }

  return (
    <FinancialTicketProvider
      value={{
        handleSearch,
        handleClickCnpjField,
        settings,
        loading,
        agroupedBranchesTicket,
        agroupedCustomersTicket,
        agroupedCustomersTicketExpired,
        selectedTicket,
        setSelectedTicket,
        filter,
        handleChangeFilter,
      }}
    >
      {saving && <Loading />}

      {failedTickets.length > 0 && (
        <FailedSendTicketsDialog failedTickets={failedTickets} onExited={() => setFailedTickets([])} />
      )}
      {loadingCustomerInfo && <Loading />}
      {customerTickets.length > 0 && (
        <FinancialCustomerTicketsDialog onExited={onCloseCustomerTickets} tickets={customerTickets} />
      )}
      {dialogDate && (
        <FinancialTicketFilter
          filter={filter}
          handleChangeFilter={handleChangeFilter}
          onExited={() => setDialogDate(false)}
        />
      )}

      {shownReport && (
        <FinancialTicketData data={tickets} branch={selectedBranch} onExited={() => setShownReport(false)} />
      )}

      {excelExport && <FinancialTicketExport onExited={() => setExcelExport(false)} data={tickets} />}

      <Appbar
        backAction={handleBack}
        title="Gestão de cobranças"
        ActionsComponent={
          <>
            <IconButton color="inherit" onClick={() => setShownReport(true)}>
              <Print />
            </IconButton>
            {windowWidth < 600 && isMobile && <FilterActions handleOpenDialog={() => setDialogDate(true)} />}
            <HeaderActions>
              {checkPermission('general.excel_export_button') && (
                <Tooltip title="Exportar excel">
                  <IconButton
                    color="inherit"
                    disabled={filteredTickets.length === 0}
                    onClick={() => setExcelExport(true)}
                  >
                    <FaFileExcel />
                  </IconButton>
                </Tooltip>
              )}
            </HeaderActions>
          </>
        }
      />

      <PageHeader title="Gestão de cobrança" />

      <FinancialTicketFilterBox
        loading={loading}
        filter={filter}
        handleChangeFilter={handleChangeFilter}
        handleSearchSubmit={handleSearchSubmit}
        settings={settings}
      />

      <FinancialTicketTabs handleChange={value => setTab(value)} value={tab} />

      {tab === 'dashboard' ? (
        <>
          <SalesTicketReportTotal label="Em aberto / Em atraso" total={total} loading={loading} />
          <FinancialTicketDashboard />
        </>
      ) : tab === 'list' ? (
        <>
          <SalesTicketReportListTotal label="Em atraso / Total de boletos" tickets={tickets} loading={loading} />
          <TableContainer tableTemplate={ticketTableTemplate}>
            <PaginationProvider>
              {loading ? (
                <TableLoading />
              ) : filteredTickets.length > 0 ? (
                <Container>
                  <FinancialTicketMenu selectedTicket={selectedTicket} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
                  {displayMode === 'list' ? (
                    <TicketListTable
                      handleToggle={handleToggle}
                      selectedTickets={selectedTickets}
                      setAnchorEl={setAnchorEl}
                      handleSelectAll={handleSelectAll}
                      selectAll={selectAll}
                      tickets={filteredTickets}
                      handleSort={handleSort}
                      orderedIndex={orderedIndex}
                    />
                  ) : (
                    <TicketListModule setAnchorEl={setAnchorEl} tickets={filteredTickets} />
                  )}
                  <Flex>
                    <TicketActions
                      showAlert
                      showReminder
                      sendAlert={sendAlert}
                      sendReminder={sendReminder}
                      tickets={selectedTickets}
                    />
                    <Pagination count={filteredTickets.length} />
                  </Flex>
                </Container>
              ) : (
                <NoData message="Conteúdo indisponível" />
              )}
            </PaginationProvider>
          </TableContainer>
        </>
      ) : tab === 'expired-ticket' ? (
        <TableContainer tableTemplate={ticketTableTemplate}>
          <PaginationProvider>
            {loadingCustomers ? (
              <TableLoading />
            ) : agroupedCustomersTicketExpired.length > 0 ? (
              <Container>
                <CustomersTicketExpired
                  handleClickCnpjField={handleClickCnpjField}
                  data={agroupedCustomersTicketExpired}
                />
              </Container>
            ) : (
              <NoData message="Conteúdo indisponível" />
            )}
          </PaginationProvider>
        </TableContainer>
      ) : tab === 'due-date-reminder' ? (
        <>
          <SalesTicketReportListTotal
            label="Em atraso / Total de boletos"
            tickets={filteredTicketsDueDateReminders}
            loading={loading}
          />
          <TableContainer tableTemplate={ticketTableTemplate}>
            <PaginationProvider>
              {loadingWarnings ? (
                <TableLoading />
              ) : filteredTicketsDueDateReminders.length > 0 ? (
                <Container>
                  <FinancialTicketMenu selectedTicket={selectedTicket} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
                  {displayMode === 'list' ? (
                    <TicketListTable
                      handleToggle={handleToggleDueDateReminders}
                      selectedTickets={selectedTicketsDueDateReminders}
                      setAnchorEl={setAnchorEl}
                      handleSelectAll={handleSelectAllDueDateReminders}
                      selectAll={selectAllDueDateReminders}
                      tickets={filteredTicketsDueDateReminders}
                      handleSort={handleSortDueDateReminders}
                      orderedIndex={orderedIndex}
                    />
                  ) : (
                    <TicketListModule setAnchorEl={setAnchorEl} tickets={filteredTicketsDueDateReminders} />
                  )}
                  <Flex>
                    <TicketActions
                      showReminder
                      showAlert={false}
                      sendAlert={sendAlertDueDateReminders}
                      sendReminder={sendReminderDueDateReminders}
                      tickets={selectedTicketsDueDateReminders}
                    />
                    <Pagination count={filteredTicketsDueDateReminders.length} />
                  </Flex>
                </Container>
              ) : (
                <NoData message="Conteúdo indisponível" />
              )}
            </PaginationProvider>
          </TableContainer>
        </>
      ) : tab === 'billing-notice' ? (
        <>
          <SalesTicketReportListTotal
            label="Em atraso / Total de boletos"
            tickets={filteredTicketsBillingNotice}
            loading={loading}
          />
          <TableContainer tableTemplate={ticketTableTemplate}>
            <PaginationProvider>
              {loadingWarnings ? (
                <TableLoading />
              ) : filteredTicketsBillingNotice.length > 0 ? (
                <Container>
                  <FinancialTicketMenu selectedTicket={selectedTicket} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
                  {displayMode === 'list' ? (
                    <TicketListTable
                      handleToggle={handleToggleBillingNotice}
                      selectedTickets={selectedTicketsBillingNotice}
                      setAnchorEl={setAnchorEl}
                      handleSelectAll={handleSelectAllBillingNotice}
                      selectAll={selectAllBillingNotice}
                      tickets={filteredTicketsBillingNotice}
                      handleSort={handleSortBillingNotice}
                      orderedIndex={orderedIndex}
                    />
                  ) : (
                    <TicketListModule setAnchorEl={setAnchorEl} tickets={filteredTicketsBillingNotice} />
                  )}
                  <Flex>
                    <TicketActions
                      showReminder={false}
                      showAlert
                      sendAlert={sendAlertBillingNotice}
                      sendReminder={sendReminderBillingNotice}
                      tickets={selectedTicketsBillingNotice}
                    />
                    <Pagination count={filteredTicketsBillingNotice.length} />
                  </Flex>
                </Container>
              ) : (
                <NoData message="Conteúdo indisponível" />
              )}
            </PaginationProvider>
          </TableContainer>
        </>
      ) : (
        <PaginationProvider>
          {loadingCustomers ? (
            <TableLoading />
          ) : (
            <TopCustomersTicket
              handleClickCnpjField={handleClickCnpjField}
              data={agroupedCustomerWithCreditLimitTicket}
            />
          )}
        </PaginationProvider>
      )}
    </FinancialTicketProvider>
  );
};

export default FinancialTicketReport;
