import Dialog from 'components/dialogs/Dialog';
import React, { useEffect, useState } from 'react';
import InvoiceActions from './InvoiceActions';
import { MenuItem, TextField, Typography, styled } from '@mui/material';
import DesktopDatePicker from 'components/pickers/DesktopDatePicker';
import { moneyFormat } from 'helpers/numberFormat';
import { Receipt, RequestQuote } from '@mui/icons-material';
import { Invoice } from 'types/invoices';
import { useApp } from 'hooks/app';
import { useSelector } from 'store/selector';
import { format } from 'date-fns';
import { useMessaging } from 'hooks/messaging';
import InsideSaving from 'components/loading/InsideSaving';
import { parsePtBRDate } from 'helpers/parsePtBRDate';
import { TotalizingAccount } from 'types/totalizingAccount';
import InsideLoading from 'components/loading/InsideLoading';

interface InvoicePaymentProps {
  onExited(): void;
  onSave(): void;
  invoices: Invoice[];
  branchId: number;
}

const Form = styled('form')({
  marginTop: 30,
});

const Box = styled('div')(({ theme }) => ({
  minWidth: 225,
  flex: 1,
  display: 'grid',
  gridTemplateColumns: '50px 1fr',
  alignItems: 'center',
  gap: 15,
  border: `1px solid ${theme.palette.primary.dark}`,
  '& .value': {
    fontSize: 24,
    textAlign: 'end',
    paddingRight: 5,
  },
  '& svg': {
    paddingLeft: 5,
    width: '100%',
    height: '100%',
    color: theme.palette.primary.main,
  },
  '& .description': {
    fontWeight: 600,
    padding: '10px 5px',
    gridColumn: '1/3',
    textAlign: 'end',
    borderTop: `1px solid ${theme.palette.primary.dark}`,
  },
}));

const Header = styled('div')(({ theme }) => ({
  gap: 10,
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  maxHeight: 225,
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    maxHeight: 430,
  },
}));

const Item = styled('li')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '60px 1fr 90px 90px',
  gap: 5,
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '60px 100px 90px 90px',
  },
  '&.header': {
    backgroundColor: '#eee',
  },
}));

const List = styled('ul')(({ theme }) => ({
  marginTop: 20,
  display: 'flex',
  flexDirection: 'column',
  gap: 5,
  [theme.breakpoints.down('sm')]: {
    paddingBottom: 20,
    overflow: 'scroll',
  },
}));

const Content = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: 15,
  gridTemplateColumns: '1fr 1fr 1fr',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

const InvoicePayment: React.FC<InvoicePaymentProps> = ({ onExited, onSave, invoices, branchId }) => {
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const { handleOpen } = useMessaging();
  const { financialApi } = useApp();
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [totals, setTotals] = useState('');
  const total = invoices.reduce((sum, item) => sum + item.valor, 0);
  const length = invoices.length;
  const user = useSelector(state => state.user);
  const [totalizingAccounts, setTotalizingAccounts] = useState<TotalizingAccount[]>([]);
  const [targetAccountId, setTargetAccountId] = useState(0);

  useEffect(() => {
    setLoading(true);
    financialApi
      .get('/getcontastotalizadoras', {
        params: {
          id_filial: branchId,
        },
      })
      .then(response => {
        if (response.data.MESSAGE) {
          setTotalizingAccounts([]);
          return;
        }
        setTotalizingAccounts(
          response.data.map(item => {
            item.formattedSaldo = moneyFormat(item.saldo);
            item.data = new Date(parsePtBRDate(item.data_inicio));
            return item;
          }),
        );
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [branchId, financialApi]);

  useEffect(() => {
    setTotals(invoices.reduce((sum, item) => sum + item.valor - item.valor_pago, 0).toString());
  }, [invoices]);

  function handleSubmit() {
    if (!financialApi) return;
    setSaving(true);

    const data = invoices.map(item => ({
      valor_pago: invoices.length === 1 ? totals : item.valor,
      data_pagamento: format(paymentDate, 'dd/MM/yyyy'),
      id_conta_totalizadora: targetAccountId || '',
      usuario: user?.name || '',
      id: item.id,
    }));

    financialApi
      .post('/savepagamentofinanceiro', data)
      .then(response => {
        if (response.data.MESSAGE) {
          onSave();
          return;
        }

        handleOpen(response.data.message);
      })
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  return (
    <Dialog
      height="70vh"
      maxWidth="sm"
      onExited={onExited}
      ComponentActions={<InvoiceActions handleSubmit={handleSubmit} />}
      title="Pagamento"
    >
      {saving && <InsideSaving />}
      <Header>
        <Box>
          <Receipt />
          <Typography className="value" fontSize={12} variant="subtitle2">
            {length}
          </Typography>
          <Typography className="description" variant="caption">
            Quantidade de títulos
          </Typography>
        </Box>

        <Box>
          <RequestQuote />
          <Typography className="value" fontSize={12} variant="subtitle2">
            {moneyFormat(total)}
          </Typography>
          <Typography className="description" variant="caption">
            (R$) Total
          </Typography>
        </Box>
      </Header>

      <Form>
        <Content>
          {length === 1 && (
            <TextField
              label="Valor pago (R$)"
              autoFocus
              type="number"
              value={totals}
              onChange={e => setTotals(e.target.value)}
              inputMode="decimal"
            />
          )}

          <DesktopDatePicker
            maxDate={new Date()}
            label="Data de pagamento"
            value={paymentDate}
            onChange={date => setPaymentDate(date)}
          />

          <TextField
            select
            value={targetAccountId}
            placeholder="Selecione a conta totalizadora"
            label="Conta totalizadora"
            onChange={e => setTargetAccountId(parseInt(e.target.value))}
          >
            <MenuItem value={0}>Nenhuma conta selecionada</MenuItem>

            {loading ? (
              <InsideLoading />
            ) : (
              totalizingAccounts.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.nome}
                </MenuItem>
              ))
            )}
          </TextField>
        </Content>
        <List>
          <Typography variant="subtitle2">Faturas selecionadas:</Typography>
          <Item className="header">
            <Typography variant="subtitle1" fontWeight={600} fontSize={13}>
              ID
            </Typography>
            <Typography variant="subtitle1" fontWeight={600} fontSize={13}>
              Título
            </Typography>
            <Typography align="right" variant="subtitle1" fontWeight={600} fontSize={13}>
              Total
            </Typography>
            <Typography align="right" variant="subtitle1" fontWeight={600} fontSize={13}>
              Valor falta
            </Typography>
          </Item>
          {invoices.map((item, index) => (
            <Item key={index}>
              <Typography variant="caption">{item.id}</Typography>
              <Typography variant="caption">{item.titulo}</Typography>
              <Typography variant="caption" align="right">
                {item.formattedValue}
              </Typography>
              <Typography variant="caption" align="right">
                {moneyFormat(item.valor_falta)}
              </Typography>
            </Item>
          ))}
        </List>
      </Form>
    </Dialog>
  );
};

export default InvoicePayment;
