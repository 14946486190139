import { Button, styled, TextField, Typography } from '@mui/material';
import DialogInput from 'components/dialogs/DialogInput';
import React, { useState } from 'react';

interface Props {
  originalPhone: string;
  onExited: () => void;
  handleSendWhatsApp: (phone: string) => void;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
  flex: 1,
  justifyContent: 'center',
});

const DialogTitle = styled(Typography)({
  fontSize: 20,
  fontWeight: 600,
});

const DialogActions = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 15,
});

const PhoneChange: React.FC<Props> = ({ originalPhone, onExited, handleSendWhatsApp }) => {
  const [phone, setPhone] = useState(originalPhone);

  return (
    <DialogInput onExited={onExited} hideBackdrop maxWidth="xs">
      <Container>
        <DialogTitle>Alterar Telefone</DialogTitle>
        <TextField autoFocus label="Telefone" value={phone} onChange={e => setPhone(e.target.value)} />

        <DialogActions>
          <Button onClick={onExited} variant="outlined" color="error">
            Cancelar
          </Button>
          <Button onClick={() => handleSendWhatsApp(phone)} color="success" variant="contained">
            Enviar WhatsApp
          </Button>
        </DialogActions>
      </Container>
    </DialogInput>
  );
};

export default PhoneChange;
