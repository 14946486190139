import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { IconButton, Tooltip, styled } from '@mui/material';
import Pagination from 'components/pagination/Pagination';
import Appbar from 'components/appbar/Appbar';
import { useApp } from 'hooks/app';
import useTableOrder from 'hooks/tableOrder';
import TableContainer from 'components/table/TableContainer';
import PaginationProvider from 'hooks/pagination';
import { endOfDay, subDays } from 'date-fns';
import FilterActions from 'components/filter-box/FilterActions';
import { useSelector } from 'store/selector';
import { usePermissionRules } from 'hooks/permissionRules';
import { useFinancial } from 'pages/financial/hooks/useFinancial';
import { Print } from '@mui/icons-material';
import { useDisplayMode } from 'hooks/useDisplayMode';
import { FaFileExcel } from 'react-icons/fa';
import NoData from 'components/no-data/NoData';
import TableLoading from 'components/loading/TableLoading';
import { FinancialTicket } from 'types/financialReportList';
import { FinancialTicketFilterParams, useFetchFinancialTicketReport } from './hooks/useFetchFinancialTicketReport';
import FinancialTicketData from 'pages/reports/financial/receivable/ticket/FinancialTicketData';
import FinancialTicketExport from 'pages/reports/financial/receivable/ticket/export/FinancialTicketExport';
import FinancialTicketFilterBox from './FinancialTicketFilterBox';
import SalesTicketReportListTotal from 'pages/reports/financial/receivable/ticket/SalesTicketReportListTotal';
import { ticketTableTemplate } from './list/template/ticketTableTemplate';
import FinancialTicketMenu from 'pages/reports/financial/receivable/ticket/FinancialTicketMenu';
import TicketListTable from './list/table/TicketListTable';
import TicketListModule from './list/module/TicketListModule';
import FinancialTicketFilter from 'pages/reports/financial/receivable/ticket/FinancialTicketFilter';
import PageHeader from 'components/page-header/PageHeader';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const HeaderActions = styled('div')({
  display: 'flex',
  columnGap: '10px',
});

const FinancialTicketPage: React.FC = () => {
  const { handleBack } = useFinancial();
  const { windowWidth, isMobile } = useApp();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [displayMode] = useDisplayMode();
  const [dialogDate, setDialogDate] = useState(false);
  const [shownReport, setShownReport] = useState(false);
  const [orderedIndex, sort] = useTableOrder();
  const [filteredTickets, setFilteredTickets] = useState<FinancialTicket[]>([]);
  const [selectedTicket, setSelectedTicket] = useState<FinancialTicket | null>(null);
  const [excelExport, setExcelExport] = useState(false);
  const user = useSelector(state => state.user);
  const branches = useSelector(state => state.branches);
  const [filter, setFilter] = useState<FinancialTicketFilterParams>({
    initialDate: endOfDay(subDays(new Date(), 1)),
    finalDate: endOfDay(new Date()),
    branchId:
      user && user.branchList?.length !== 0
        ? parseInt(user.branchList.length === branches.length ? '0' : user.branchList[0])
        : 999,
    situation: 'N',
    typeDate: 'V',
    bank_billet_setting_id: null,
    search: '',
    dropoffLocation: 'T',
  });
  const { handleSearch, tickets, loading, settings } = useFetchFinancialTicketReport(filter);
  const { checkPermission } = usePermissionRules();

  const selectedBranch = useMemo(() => {
    const branch = branches.find(branch => branch.id === filter.branchId);

    if (!branch) {
      return null;
    }

    return branch;
  }, [filter, branches]);

  useEffect(() => {
    setFilteredTickets(tickets);
  }, [tickets]);

  function handleChangeFilter(index: keyof FinancialTicketFilterParams, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleSort(index: string) {
    const filtered = sort(index, filteredTickets);
    setFilteredTickets(filtered);
  }

  function handleSearchSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    handleSearch();
  }

  return (
    <>
      {dialogDate && (
        <FinancialTicketFilter
          filter={filter}
          handleChangeFilter={handleChangeFilter}
          onExited={() => setDialogDate(false)}
        />
      )}

      {shownReport && (
        <FinancialTicketData data={tickets} branch={selectedBranch} onExited={() => setShownReport(false)} />
      )}

      {excelExport && <FinancialTicketExport onExited={() => setExcelExport(false)} data={tickets} />}

      <Appbar
        backAction={handleBack}
        title="Boletos"
        ActionsComponent={
          <>
            <IconButton color="inherit" onClick={() => setShownReport(true)}>
              <Print />
            </IconButton>
            {windowWidth < 600 && isMobile && <FilterActions handleOpenDialog={() => setDialogDate(true)} />}
            <HeaderActions>
              {checkPermission('general.excel_export_button') && (
                <Tooltip title="Exportar excel">
                  <IconButton
                    color="inherit"
                    disabled={filteredTickets.length === 0}
                    onClick={() => setExcelExport(true)}
                  >
                    <FaFileExcel />
                  </IconButton>
                </Tooltip>
              )}
            </HeaderActions>
          </>
        }
      />

      <PageHeader title="Relação de boletos" />

      <FinancialTicketFilterBox
        loading={loading}
        filter={filter}
        handleChangeFilter={handleChangeFilter}
        handleSearchSubmit={handleSearchSubmit}
        settings={settings}
      />

      <SalesTicketReportListTotal label="Em atraso / Total de boletos" tickets={tickets} loading={loading} />
      <TableContainer tableTemplate={ticketTableTemplate}>
        <PaginationProvider>
          {loading ? (
            <TableLoading />
          ) : filteredTickets.length > 0 ? (
            <Container>
              <FinancialTicketMenu selectedTicket={selectedTicket} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
              {displayMode === 'list' ? (
                <TicketListTable
                  setAnchorEl={setAnchorEl}
                  setSelectedTicket={setSelectedTicket}
                  tickets={filteredTickets}
                  handleSort={handleSort}
                  orderedIndex={orderedIndex}
                />
              ) : (
                <TicketListModule
                  setSelectedTicket={setSelectedTicket}
                  setAnchorEl={setAnchorEl}
                  tickets={filteredTickets}
                />
              )}
              <Pagination count={filteredTickets.length} />
            </Container>
          ) : (
            <NoData message="Conteúdo indisponível" />
          )}
        </PaginationProvider>
      </TableContainer>
    </>
  );
};

export default FinancialTicketPage;
