import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { Typography, ListItem, IconButton, styled } from '@mui/material';
import { FinancialTicket } from 'types/financialReportList';
import { MoreHoriz } from '@mui/icons-material';

const StyledListItem = styled(ListItem)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  position: 'relative',
});

const Grid = styled('div')({
  display: 'grid',
  width: '100%',
  alignItems: 'center',
  gridTemplateColumns: '80px 1fr',
});

const CustomIconButton = styled(IconButton)({
  position: 'absolute',
  top: 10,
  right: 0,
});

type TicketItemModuleProps = {
  ticket: FinancialTicket;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
  setSelectedTicket(ticket: FinancialTicket): void;
};

const TicketItemModule: React.FC<TicketItemModuleProps> = ({ ticket, setAnchorEl, setSelectedTicket }) => {
  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedTicket(ticket);
  }

  return (
    <StyledListItem>
      <CustomIconButton onClick={handleClick}>
        <MoreHoriz />
      </CustomIconButton>

      <Grid>
        <Typography variant="caption">Cliente:</Typography>
        <Typography variant="button">{ticket.cliente}</Typography>
      </Grid>

      <Grid>
        <Typography variant="caption">Filial:</Typography>
        <Typography variant="subtitle2" color="primary">
          {ticket.filial}
        </Typography>
      </Grid>

      <Grid>
        <Typography variant="caption">Data lanc:</Typography>
        <Typography color="primary" variant="body2">
          {ticket.data_lanc}
        </Typography>
      </Grid>

      <Grid>
        <Typography variant="caption">Data venc:</Typography>
        <Typography color="primary" variant="body2">
          {ticket.vencimento}
        </Typography>
      </Grid>

      <Grid>
        <Typography variant="caption">Data pag:</Typography>
        <Typography variant="subtitle2" color="primary">
          {ticket.data_pag}
        </Typography>
      </Grid>

      <Grid>
        <Typography variant="caption">Juros:</Typography>
        <Typography color="#f30000" variant="body2">
          {ticket.formattedFees}
        </Typography>
      </Grid>

      <Grid>
        <Typography variant="caption">Multa:</Typography>
        <Typography color="#f30000" variant="body2">
          {ticket.formattedTrafficTicket}
        </Typography>
      </Grid>

      <Typography color="green" fontWeight={600} fontSize={16} variant="button">
        {ticket.formattedValue}
      </Typography>
    </StyledListItem>
  );
};

export default TicketItemModule;
