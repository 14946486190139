import { Button } from '@mui/material';
import React from 'react';

interface Props {
  tickets: any[];
  sendReminder(): void;
  sendAlert(): void;
  showReminder: boolean;
  showAlert: boolean;
}

const TicketActions: React.FC<Props> = ({ sendReminder, sendAlert, tickets, showAlert, showReminder }) => {
  return (
    <div className="actions" style={{ flex: 1, display: 'flex', gap: 5, alignItems: 'center' }}>
      {tickets.length > 0 && (
        <>
          {showReminder && (
            <Button variant="contained" onClick={sendReminder}>
              Enviar lembrete de vencimento
            </Button>
          )}

          {showAlert && (
            <Button variant="contained" color="secondary" onClick={sendAlert}>
              Enviar aviso de débito
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default TicketActions;
