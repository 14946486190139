import React, { useEffect, useMemo, useState } from 'react';
import Dialog from 'components/dialogs/Dialog';
import InsideSaving from 'components/loading/InsideSaving';
import { Button, styled } from '@mui/material';
import { useResellers } from '../hooks/useResellers';
import ResellerApprovalTabs, { ResellerAprovalTabValue } from './ResellerApprovalTabs';
import ResellerApprovalPartner from './ResellerApprovalPartner';
import { api } from 'services/api';
import ResellerApprovalSegment from './ResellerApprovalSegment';
import { useApp } from 'hooks/app';
import { useMessaging } from 'hooks/messaging';
import ResellerRejection from '../rejection/ResellerRejection';
import ResellerApprovalObservation from './ResellerApprovalObservation';
import ResellerApprovalComercial from './ResellerApprovalComercial';
import { Seller } from 'types/seller';
import { Reseller, ResellerDocument } from 'types/reseller';
import { ResellerProvider } from '../hooks/useReseller';
import ResellerApprovalCompany from './ResellerApprovalCompany';
import ResellerActions from './ResellerActions';
import { Address } from 'types/address';
import { useResellerValidation } from './validations/resellerValidation';
import ResellerSintegraBA from './ResellerSintegraBA';
import OtherDocuments from './other-documents/OtherDocuments';

const Container = styled('div')(({ theme }) => ({
  marginTop: 20,
  marginBottom: 65,
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  [theme.breakpoints.down('sm')]: {
    padding: 'unset',
  },
  '& label': {
    fontSize: 12,
  },
}));

const Actions = styled('div')({
  display: 'flex',
  justifyContent: 'space-around',
  position: 'absolute',
  bottom: 0,
  right: 0,
  left: 0,
  borderTop: '1px solid #eee',
  backgroundColor: '#fff',
  height: 65,
  alignItems: 'center',
});

type ResellerApprovalProps = {
  onExited(): void;
};

const ResellerAproval: React.FC<ResellerApprovalProps> = ({ onExited }) => {
  const [saving, setSaving] = useState(false);
  const { selectedReseller, handleRemoveFromList } = useResellers();
  const [tab, setTab] = useState<ResellerAprovalTabValue>('company');
  const [dialogConfirm, setDialogConfirm] = useState(false);
  const { h2iApi } = useApp();
  const messaging = useMessaging();
  const [dialogRejection, setDialogRejection] = useState(false);
  const [reseller, setReseller] = useState<Reseller | null>(JSON.parse(JSON.stringify(selectedReseller)));
  const { handleSearch, filter } = useResellers();
  const [validation, setValidation, validate] = useResellerValidation();
  const [sintegraResponse, setSintegraResponse] = useState('');

  const isSelfEmployed = useMemo(() => reseller?.document.length === 11, [reseller]);

  useEffect(() => {
    if (!selectedReseller) return;
    validate(selectedReseller, isSelfEmployed).catch(err => console.error(err));
  }, [selectedReseller, validate, isSelfEmployed]);

  function handleReject(reason: string, handleClose: () => void) {
    if (!reseller) return;

    setSaving(true);

    api
      .post(`/resellers/${reseller.id}/reject`, { reason })
      .then(() => {
        handleRemoveFromList(reseller.id);
        handleClose();
      })
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  async function handleApprove(segmentId: number, handleCloseDialog: () => void, seller?: Seller) {
    if (!reseller) return;
    if (!h2iApi) return;

    const data = {
      nome: reseller.company_name,
      fantasia: reseller.company_trade_name,
      cnpj_cpf: reseller.document,
      senha: reseller.company_name,
      email: reseller.email,
      telefone: reseller.phone,
      endereco: `${reseller.address.street}, ${reseller.address.street_number}`,
      complemento: reseller.address.complement ? reseller.address.complement.substr(0, 30) : null,
      bairro: reseller.address.neighborhood.substr(0, 30),
      cidade: reseller.address.city,
      uf: reseller.address.state,
      cep: reseller.address.postal_code,
      codigo_atividade: segmentId,
      rg_ie: reseller.ie,
      whatsapp: reseller.phone,
      link_selfie: reseller.partner_selfie?.imageUrl,
      link_documento: reseller.partner_document_copy.document_url,
      link_contrato_social: reseller.social_contract_copy?.document_url,
      link_residencia: reseller.partner_proof_residence_copy?.document_url,
      link_declaracao: reseller.self_employed_declaration?.document_url,
      link_procuracao: reseller.letter_of_attorney?.document_url,
    };

    let agent = {
      id_filial: 0,
      nome: reseller.partner_name,
      cpf: reseller.partner_document,
      rg: reseller.partner_rg,
      documentos: [],
      telefone: reseller.partner_phone,
      cep: reseller.partner_address?.postal_code || '',
      endereco: reseller.partner_address?.street || '',
      numero_endereco: reseller.partner_address?.street_number || '',
      bairro: reseller.partner_address?.neighborhood || '',
      cidade: reseller.partner_address?.city || '',
      uf: reseller.partner_address?.state || '',
      rma: 'S',
      compra: 'S',
      retirada: 'S',
      ativo: 'S',
      codigo_cliente: '',
      cnpj_cpf_cadastro: '',
      socio: 'S',
    };

    setSaving(true);

    try {
      const resellerResponse = await h2iApi.post('/api/savecliente', data);

      if (!('Result' in resellerResponse.data)) throw new Error('Não foi possível salvar o cliente');

      if (!resellerResponse.data.Result) {
        messaging.handleOpen(resellerResponse.data.Value);
        setSaving(false);
        return;
      }

      const agentDocument = {
        cpf_preposto: reseller.partner_document,
        link: reseller.partner_document_copy.document_url,
        tipo: 'D',
        id_cliente: resellerResponse.data.id,
      };

      agent = {
        ...agent,
        id_filial: resellerResponse.data.id_filial,
        codigo_cliente: resellerResponse.data.id,
        cnpj_cpf_cadastro: resellerResponse.data.cnpj,
      };

      await h2iApi.post('/api/savepreposto', agent).catch(() => {
        throw new Error('Não foi possível salvar o preposto');
      });

      await h2iApi.post('/api/saveprepostodocumento', agentDocument).catch(() => {
        throw new Error('Não possível salvar o o documento do preposto');
      });

      await api.post(`/resellers/${reseller.id}/approve`, {
        password: resellerResponse.data.senha,
        sellerEmail: seller?.email,
        sellerName: seller?.nome,
      });

      handleCloseDialog();
      handleRemoveFromList(reseller.id);
    } catch (err) {
      messaging.handleOpen(err as string);
    }
  }

  function handleChange(index: keyof Reseller, value: any) {
    if (!reseller) return;

    setReseller(state => {
      if (!state) return null;
      return {
        ...state,
        [index]: value,
      };
    });
  }

  function handlePartnerAddressChange(index: keyof Address, value: any) {
    if (!reseller) {
      return;
    }

    setReseller(state =>
      state
        ? {
            ...state,
            partner_address: state.partner_address
              ? {
                  ...state.partner_address,
                  [index]: value,
                }
              : {
                  city: '',
                  complement: '',
                  neighborhood: '',
                  postal_code: '',
                  state: '',
                  street: '',
                  street_number: '',
                },
          }
        : null,
    );
  }

  function handleValidation() {
    if (!reseller) return;

    setValidation({});

    validate(reseller, isSelfEmployed)
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/resellers/${reseller?.id}`, reseller)
      .then(() => {
        handleSearch(filter);

        messaging.handleOpen('Salvo');
      })
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  function sintegraBaRequest() {
    setSaving(true);

    const body = {
      cnpj: reseller?.document,
      selectedState: 'da Bahia',
      selectedUf: 'BA',
    };

    api
      .post('/sintegra-ba', body)
      .then(response => setSintegraResponse(response.data))
      .catch(() => messaging.handleOpen('Não foi possível realizar a consulta'))
      .finally(() => setSaving(false));
  }

  function handleDeleteDocument(documentId: number) {
    setReseller(state => {
      if (!state) return null;
      return {
        ...state,
        documents: state.documents.filter(document => document.id !== documentId),
      };
    });
  }

  function handleAddDocument(document: ResellerDocument) {
    setReseller(state => {
      if (!state) return null;
      return {
        ...state,
        documents: [...state.documents, document],
      };
    });
  }

  return (
    <ResellerProvider
      value={{
        handleChange,
        reseller,
        handlePartnerAddressChange,
        sintegraBaRequest,
        isSelfEmployed,
        handleDeleteDocument,
        handleAddDocument,
      }}
    >
      <Dialog
        onExited={onExited}
        maxWidth="md"
        height="80vh"
        title={reseller?.type === 'store' ? 'Aprovar cadastro de revendedor' : 'Aprovar atualização cadastral'}
        ComponentActions={<ResellerActions handleSubmit={handleValidation} />}
      >
        {sintegraResponse && <ResellerSintegraBA html={sintegraResponse} onExited={() => setSintegraResponse('')} />}
        {dialogConfirm && (
          <ResellerApprovalSegment onExited={() => setDialogConfirm(false)} handleApprove={handleApprove} />
        )}
        {dialogRejection && (
          <ResellerRejection onExited={() => setDialogRejection(false)} handleReject={handleReject} />
        )}
        <ResellerApprovalTabs tab={tab} handleChange={value => setTab(value)} />
        {saving && <InsideSaving />}
        <Container>
          {tab === 'company' ? (
            <ResellerApprovalCompany reseller={reseller} validation={validation} />
          ) : tab === 'partner' ? (
            <ResellerApprovalPartner />
          ) : tab === 'observation' ? (
            <ResellerApprovalObservation reason={reseller?.reason} />
          ) : tab === 'comercial' ? (
            <ResellerApprovalComercial />
          ) : (
            tab === 'otherDocuments' && <OtherDocuments />
          )}
          {reseller?.status !== 'approved' && (
            <Actions>
              {reseller?.status === 'waiting_analysis' && (
                <Button onClick={() => setDialogRejection(true)} variant="text" color="primary">
                  Rejeitar
                </Button>
              )}
              <Button variant="contained" color="primary" onClick={() => setDialogConfirm(true)}>
                Aprovar
              </Button>
            </Actions>
          )}
        </Container>
      </Dialog>
    </ResellerProvider>
  );
};

export default ResellerAproval;
