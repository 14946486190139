import React from 'react';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { usePagination } from 'hooks/pagination';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import TableContainer from 'components/table/TableContainer';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Typography, Grid } from '@mui/material';
import { AgroupedAbcProductPayment } from 'types/abcProduct';
import { agroupedPaymentTableTemplate } from '../../agroupedPaymentTableTemplate';
import AgroupedPaymentItemTable from './AgroupedPaymentItemTable';

type AgroupedPaymentListTableProps = {
  payments: AgroupedAbcProductPayment[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const AgroupedPaymentListTable: React.FC<AgroupedPaymentListTableProps> = ({ payments, handleSort, orderedIndex }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer tableTemplate={agroupedPaymentTableTemplate}>
          <TableContent>
            <TableHeader>
              {agroupedPaymentTableTemplate.map(item => (
                <div
                  className={item.dataType === 'number' ? `numericData` : ''}
                  key={item.id}
                  onClick={() => handleSort(item.originalId)}
                >
                  <Typography style={{ fontWeight: 'bold' }} variant="caption" color="primary">
                    {item.description}
                  </Typography>
                  {orderedIndex.index === item.originalId &&
                    (orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    ))}
                </div>
              ))}
            </TableHeader>
            <TableBody useMaxHeight maxHeight={500}>
              {payments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((payment, index) => (
                <TableRow style={{ minHeight: 10, padding: 0 }} key={index}>
                  <AgroupedPaymentItemTable payment={payment} />
                </TableRow>
              ))}
            </TableBody>
          </TableContent>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default AgroupedPaymentListTable;
