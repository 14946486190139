import React from 'react';
import { MenuItem, TextField } from '@mui/material';
import DialogInput from 'components/dialogs/DialogInput';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';

type FinancialTicketFilterProps = {
  onExited(): void;
  filter: any;
  handleChangeFilter(index: string, value: any): void;
};

const FinancialTicketFilter: React.FC<FinancialTicketFilterProps> = ({ onExited, filter, handleChangeFilter }) => {
  return (
    <DialogInput onExited={onExited} maxWidth="xs">
      <div style={{ display: 'grid', rowGap: 15, width: '100%', zoom: '90%' }}>
        <TextField
          fullWidth
          value={filter.typeDate}
          onChange={e => handleChangeFilter('typeDate', e.target.value)}
          select
          label="Tipo da data"
        >
          <MenuItem value={'V'}>Vencimento</MenuItem>
          <MenuItem value={'L'}>Lançamento</MenuItem>
          <MenuItem value={'P'}>Pagamento</MenuItem>
        </TextField>
        <TextField
          fullWidth
          value={filter.situation}
          onChange={e => handleChangeFilter('situation', e.target.value)}
          select
          label="Situação"
        >
          <MenuItem value={'A'}>Apropriados</MenuItem>
          <MenuItem value={'N'}>Não apropriados</MenuItem>
          <MenuItem value={'T'}>Todos</MenuItem>
        </TextField>

        <TextField
          value={filter.dropoffLocation}
          onChange={e => handleChangeFilter('dropoffLocation', e.target.value)}
          fullWidth
          select
          label="Local Baixa"
        >
          <MenuItem value={'L'}>Manual</MenuItem>
          <MenuItem value={'B'}>Boleto</MenuItem>
          <MenuItem value={'P'}>Pix</MenuItem>
          <MenuItem value={'T'}>Todos</MenuItem>
        </TextField>

        <KeyboardDatePicker
          label="Data inicial"
          value={filter.initialDate}
          onChange={date => handleChangeFilter('initialDate', date)}
        />
        <KeyboardDatePicker
          label="Data Final"
          value={filter.finalDate}
          onChange={date => handleChangeFilter('finalDate', date)}
        />
      </div>
    </DialogInput>
  );
};

export default FinancialTicketFilter;
