import { ArrowBack } from '@mui/icons-material';
import { Button, styled } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import React from 'react';

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  alignItems: 'flex-start',
  '& > iframe': {
    minHeight: '85vh',
  },
});

const ProjectForm: React.FC = () => {
  return (
    <>
      <Appbar title="Projeto" />
      <Container>
        <Button onClick={() => history.back()} startIcon={<ArrowBack />}>
          Voltar
        </Button>
        <iframe src="https://webforms.pipedrive.com/f/cs5dujnyJf86Qdsd3vJcckocvhUUmEKt4vvroeBWVBhYYfq4nOXb8I4XlWeUt1yDTB"></iframe>
      </Container>
    </>
  );
};

export default ProjectForm;
