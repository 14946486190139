import React, { useEffect, useState } from 'react';
import { IconButton, Tooltip, Typography, styled } from '@mui/material';
import { moneyFormat } from 'helpers/numberFormat';
import useTableOrder from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward, MoreHoriz } from '@mui/icons-material';
import { CustomerFinancialTicket } from 'types/financialReportList';
import { usePagination } from 'hooks/pagination';
import Pagination from 'components/pagination/Pagination';
import NoData from 'components/no-data/NoData';
import CustomersTicketMenu from './CustomersTicketMenu';

const Container = styled('div')({
  gap: 10,
  borderRadius: 6,
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #eee',
  padding: 20,
  background: '#fff',
  overflow: 'auto',
  height: 650,
});

const Row = styled('li')(({ theme }) => ({
  display: 'grid',
  columnGap: 10,
  borderBottom: '1px solid #eee',
  paddingBottom: 6,
  gridTemplateColumns: '60px 100px 115px 110px 105px 105px 90px',
  '& > span': {
    gap: 5,
    fontSize: 11,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  '& > .numericData': {
    justifyContent: 'flex-end',
    textAlign: 'end',
  },
  '& > .quantityData': {
    justifyContent: 'center',
    textAlign: 'center',
  },
  '& .item-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: '60px 1.5fr 1fr 110px 1fr 1fr 1fr',
  },
}));

const List = styled('ul')({
  display: 'grid',
  rowGap: 10,
});

interface CustomersTicketExpiredProps {
  data: CustomerFinancialTicket[];
  handleClickCnpjField(cnpj: string): void;
}

const CustomersTicketExpired: React.FC<CustomersTicketExpiredProps> = ({ data, handleClickCnpjField }) => {
  const [orderedIndex, sort] = useTableOrder();
  const [selectedTicket, setSelectedTicket] = useState<CustomerFinancialTicket | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [filtered, setFiltered] = useState<CustomerFinancialTicket[]>([]);
  const { rowsPerPage, page } = usePagination();

  useEffect(() => {
    setFiltered(data);
  }, [data]);

  function handleSort(index: string) {
    const f = sort(index, filtered);
    setFiltered(f);
  }

  function onClick(e: React.MouseEvent<HTMLButtonElement>, ticket: CustomerFinancialTicket) {
    setAnchorEl(e.currentTarget);

    setSelectedTicket(ticket);
  }

  return (
    <Container>
      {anchorEl && selectedTicket && (
        <CustomersTicketMenu anchorEl={anchorEl} selectedTicket={selectedTicket} setAnchorEl={setAnchorEl} />
      )}
      {data.length > 0 ? (
        <List>
          <Row>
            <div>
              <Typography variant="h6" fontSize={14}>
                Ações
              </Typography>
            </div>

            <span>
              <Typography fontSize={13} onClick={() => handleSort('nome')} variant="subtitle2">
                Nome
              </Typography>
              {orderedIndex.index === 'nome' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span>
              <Typography fontSize={13} onClick={() => handleSort('cnpj_cpf')} variant="subtitle2">
                CNPJ
              </Typography>
              {orderedIndex.index === 'cnpj_cpf' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span className="quantityData">
              <Typography fontSize={13} onClick={() => handleSort('dias_atraso')} align="right" variant="subtitle2">
                Dias atraso
              </Typography>
              {orderedIndex.index === 'dias_atraso' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span className="numericData">
              <Typography fontSize={13} onClick={() => handleSort('limite_credito')} align="right" variant="subtitle2">
                Limite crédito
              </Typography>
              {orderedIndex.index === 'limite_credito' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span className="numericData">
              <Typography fontSize={13} onClick={() => handleSort('valor_total')} align="right" variant="subtitle2">
                Em aberto
              </Typography>
              {orderedIndex.index === 'valor_total' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span className="numericData">
              <Typography fontSize={13} onClick={() => handleSort('vencidos')} variant="subtitle2" align="right">
                Em atraso
              </Typography>
              {orderedIndex.index === 'vencidos' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>
          </Row>
          {filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
            <Row key={index}>
              <div>
                <IconButton style={{ padding: 0 }} onClick={e => onClick(e, item)} size="small">
                  <MoreHoriz fontSize="small" />
                </IconButton>
              </div>
              <Typography align="right" variant="caption">
                {item.nome}
              </Typography>

              <Tooltip title="Clique para abrir a relação de boletos">
                <Typography
                  color="primary"
                  fontWeight={500}
                  onClick={() => handleClickCnpjField(item.cnpj_cpf)}
                  className="item-name"
                  variant="caption"
                >
                  {item.cnpj_cpf}
                </Typography>
              </Tooltip>

              <Typography className="quantityData" align="center" variant="caption">
                {item.dias_atraso}
              </Typography>

              <Typography className="numericData" align="right" variant="caption">
                {moneyFormat(item.limite_credito)}
              </Typography>
              <Typography className="numericData" align="right" variant="caption">
                {moneyFormat(item.valor_total)}
              </Typography>
              <Typography className="numericData" color="error" align="right" variant="caption">
                {moneyFormat(item.vencidos)}
              </Typography>
            </Row>
          ))}
        </List>
      ) : (
        <NoData message="Conteúdo indisponível" />
      )}
      <Pagination count={data.length} />
    </Container>
  );
};

export default CustomersTicketExpired;
