import React from 'react';
import { styled } from '@mui/material';
import InsideLoading from 'components/loading/InsideLoading';
import { useFinancialTicket } from '../../hooks/useFinancialTicket';
import PaginationProvider from 'hooks/pagination';
import TopCustomersTicket from './TopCustomersTicket';
import TopBranchesTicket from './TopBranchesTicket';

const Container = styled('div')({
  padding: 10,
  display: 'flex',
  flexDirection: 'column',
  background: '#fff',
  rowGap: 10,
  flex: 1,
  '& svg': {
    fontSize: 12,
  },
});

const ContentList = styled('div')({
  display: 'flex',
  overflow: 'auto',
  flexDirection: 'column',
});

const Content = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: 10,
  gridTemplateColumns: '1fr 1fr',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));

const FinancialTicketDashboard: React.FC = () => {
  const { loading, agroupedBranchesTicket, agroupedCustomersTicket, handleClickCnpjField } = useFinancialTicket();

  return (
    <Container>
      {loading ? (
        <InsideLoading />
      ) : (
        <Content>
          <PaginationProvider>
            <ContentList>
              <TopBranchesTicket data={agroupedBranchesTicket} />
            </ContentList>
          </PaginationProvider>

          <PaginationProvider>
            <ContentList>
              <TopCustomersTicket
                showDocument={false}
                showSelect={false}
                handleClickCnpjField={handleClickCnpjField}
                title="Endividamento por cliente"
                data={agroupedCustomersTicket}
              />
            </ContentList>
          </PaginationProvider>
        </Content>
      )}
    </Container>
  );
};

export default FinancialTicketDashboard;
